import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../request";

export const signIn = createAsyncThunk(
    'mainSlice/fetchFoods',
    async (qr_code_token) => {
        try {
            localStorage.setItem('qr_code_token', qr_code_token)
            return (await api.post('/accounts/token/', {qr_code_token})).data
        } catch (error) {
            console.log(error)
        }
    }
)

export const logout = createAsyncThunk(
  'mainSlice/logout',
  async () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('date_in')
    localStorage.removeItem('date_out')
    localStorage.removeItem('username')
    localStorage.removeItem('hotel')
    localStorage.removeItem('roomNumber')
    window.location.reload()
  }
)

export const fetchSliderInfo = createAsyncThunk(
   'mainSlice/fetchSliderInfo',
   async () => {
      try {
         return (await api.get('/info/slider-info/')).data
      } catch (error) {
         console.log(error)
      }
})

export const fetchUsefulInfo = createAsyncThunk(
  'mainSlice/fetchUsefulInfo',
  async () => {
    try {
      return (await api.get('/info/useful-info/')).data
    } catch (error) {
      console.log(error)
    }
  })

export const sendMessageToManagement = createAsyncThunk(
   'mainSlice/sendMessageToManagement',
   async (text) => {
      try {
         await api.post('/info/message/', {text})
      } catch (error) {
         console.log(error)
      }
   }
)

export const fetchRoomEquipmentInfo = createAsyncThunk(
   'mainSlice/fetchRoomEquipmentInfo',
   async () => (await api('/info/equipment-title/')).data
)

export const fetchActualDates = createAsyncThunk(
  'mainSlice/fetchActualDates',
  async () => (await api('/accounts/actual-dates/')).data
)

export const fetchNotifications = createAsyncThunk(
   'mainSlice/fetchNotifications',
   async () => (await api('/telegram/notifications/')).data
)

export const fetchDetailedNotification = createAsyncThunk(
   'mainSlice/fetchDetailedNotification',
   async ({model, id}) => {
      try {
         switch (model) {
            case 'massage':
               return (await api(`/massage/massage/${id}`)).data
            case 'cleaning':
               return (await api(`/cleaning/cleaning/${id}`)).data
            case 'drycleaning':
               return (await api(`/cleaning/dry-cleaning/${id}`)).data
            case 'kitchen':
               return (await api(`/kitchen/order/${id}`)).data
            default:
               return (await api(`/spa/${id}`)).data
         }
      } catch (error) {
         console.log(error)
      }
   }
)

export const fetchPercentForService = createAsyncThunk(
   'mainSlice/fetchPercentForService',
   async () => {
      try {
         return (await api('/info/percentage/')).data
      } catch (error) {
         console.log(error)
      }
   }
)
