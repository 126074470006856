import styled from "styled-components";
import {useRef} from "react";
import {useOutsideClick} from "../hooks";
import Icon from "../assets/Icons/Icon";

const Modal = ({active, setActive, children, closable = true}) => {
   const ref = useRef()
   useOutsideClick(ref, () => setActive(false))
   return (
      <ModalContainer className={active ? 'active' : ''}>
         <InnerContainer>
            <ModalContent ref={ref} className={active ? 'active' : ''}>
               {
                  closable && <Button onClick={() => setActive(false)}><Icon id='close'/></Button>
               }
               {children}
            </ModalContent>
         </InnerContainer>
      </ModalContainer>
   );
};

export default Modal;

const ModalContainer = styled.div`
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .2s;
  pointer-events: none;
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
`

const ModalContent = styled.div`
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #EBEBEB;
  background: #fff;
  position: relative;
  transition: .2s;
  transform: scale(.5);
  &.active {
    transform: scale(1);
  }
`

const Button = styled.button`
  background: none;
  border: transparent;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`

const InnerContainer = styled.div`
  max-width: 600px;
  padding: 0 20px;
`