export const declOfNum = (n, text_forms) => {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 === 1) {
        return text_forms[0];
    }
    return text_forms[2];
};

export const getDate = (date) => {
    const currentDate = new Date(date || Date.now());
    let day = currentDate.getDate()
    day = day < 10 ? `0${day}` : day

    let month = (currentDate.getMonth() + 1)
    month = month < 10 ? `0${month}` : month

    const year = currentDate.getFullYear();

    let hours = currentDate.getHours()
    hours = hours < 10 ? `0${hours}` : hours

    let minutes = currentDate.getMinutes()
    minutes = minutes < 10 ? `0${minutes}` : minutes
    return `${day}.${month}.${year} ${hours}:${minutes}`;
}
