import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../request";

export const fetchMassageCategories = createAsyncThunk(
   'serviceSlice/fetchMassageCategories',
   async (_, {rejectWithValue}) => {
      try {
         return (await api.get('/massage/massage-category/')).data
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const orderMassage = createAsyncThunk('' +
   'serviceSlice/orderMassage',
   async (data, {rejectWithValue}) => {
      try {
         return (await api.post('/massage/massage', data))
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const orderSpa = createAsyncThunk(
   'serviceSlice/orderSpa',
   async (data, {rejectWithValue}) => {
      try {
         return (await api.post('/spa/', data))
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const fetchDryCleaningInfo = createAsyncThunk(
   'serviceSlice/fetchDryCleaningInfo',
   async (_,{rejectWithValue}) => {
      try {
         return (await api.get('/cleaning/dry-info')).data
      } catch (error) {
         return rejectWithValue(error)
      }
   }
)

export const transportToLaundry = createAsyncThunk(
   'serviceSlice/transportToLaundry',
   (async (date_time, {rejectWithValue}) => {
      try {
         await api.post('/cleaning/dry-cleaning', {date_time})
      } catch (error) {
         return rejectWithValue(error)
      }
   })
)

export const callCleaning = createAsyncThunk(
   'serviceSlice/callCleaning',
   (async (data, {rejectWithValue}) => {
      try {
         return (await api.post('/cleaning/cleaning', data))
      } catch (error) {
         return rejectWithValue(error)
      }
   })
)
