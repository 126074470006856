import DynamicHeader from "../components/DynamicHeader";
import {Flex, Title} from "../UI/globalStyles";
import Qr from "../assets/images/qr.svg";
import UiButton from "../UI/UiButton";
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Auth = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

    return (
        <Content>
            <DynamicHeader>{t('signToAccount')}</DynamicHeader>
            <Flex gap='40px' direction='column' ai='center' jc='space-between'>
                <Title fs='24px'>{t('welcome')}</Title>
                <QrImage src={Qr} alt=""/>
                <Description>{t('authDescription')}</Description>
            </Flex>
            <Buttons>
                <Button onClick={() => navigate(-1)}><Title fs='16px' fw='400' ta='center'>{t('scanLater')}</Title></Button>
                <Link to='/qrScan' state={{auth: true}}>
                   <UiButton><Title fw='600' fs='16px' ta='center'>{t('scan')}</Title></UiButton>
                </Link>
            </Buttons>
        </Content>
    );
};

export default Auth;

const QrImage = styled.img`
  max-width: 232px;
  max-height: 238px;
`

const Description = styled.p`
  text-align: center;
`

const Button = styled.button`
  background: none;
  border: transparent;
  color: #D07B4B;
  padding: 16px 24px;
  width: 100%;
  cursor: pointer;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
`

const Buttons = styled.div`
  margin-bottom: 20px;
`