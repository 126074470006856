import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Language from "./Language";
import {useOutsideClick} from "../hooks";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/main/mainAction";
import Modal from "./Modal";
import {Flex, Title} from "../UI/globalStyles";

const Header = () => {
  const isAuth = useSelector(state => state.mainSlice.isAuth)
  const username = useSelector(state => state.mainSlice.username)
  const exitRef = useRef(null);
  const [isExitVisible, setIsExitVisible] = useState(false);
  const [showModal, setShowModal] = useState(false)
  useOutsideClick(exitRef, () => setIsExitVisible(false))
  const location = useLocation()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  const routes = {
    '/notifications': t('notifications'),
    '/management': t('writeToManagement'),
  }

  return (
    <HeaderBlock $hide={location.pathname !== '/' && !isAuth}>
      <TitleBlock>
        {
          location.pathname === '/' ? (
            isAuth ? (
              <div style={{cursor: 'pointer'}} ref={exitRef} onClick={() => setIsExitVisible(!isExitVisible)}>
                <Titleh3>{t('welcomeText', {name: username})} <Icon id='arrow'/></Titleh3>
                <Exit onClick={() => setShowModal(true)} $isVisible={isExitVisible}>
                  <Icon id='exit' height='24px' width='24px'/>{t('logout')}
                </Exit>
              </div>
            ) : (
              <Link style={{textDecoration: 'none', color: 'black'}} to="auth"><Title><Flex ai='center'><Icon id='signIn' style={{
                width: 24,
                height: 24
              }}/> {t('signIn')}</Flex></Title></Link>
            )
          ) : (
            <Titleh3>{routes[location.pathname]}</Titleh3>
          )
        }
      </TitleBlock>
      <Language/>
      <Modal active={showModal} setActive={setShowModal} closable={false}>
        <Flex direction='column' gap='20px'>
          <Title>{t('sureLogout')}</Title>
          <Flex gap='15px' jc='center'>
            <Button $primary onClick={() => setShowModal(false)}>{t('no')}</Button>
            <Button onClick={() => dispatch(logout())}>{t('yes')}</Button>
          </Flex>
        </Flex>
      </Modal>
    </HeaderBlock>
  );
};

export default Header;

const HeaderBlock = styled.div`
  position: sticky;
  top: 0;
  max-width: 600px;
  width: 100%;
  height: 60px;
  display: ${({$hide}) => $hide ? 'none' : 'flex'};
  justify-content: space-between;
  align-items: center;
  background: #fff;
  z-index: 2;
`

const Titleh3 = styled.h3`
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
`

const Exit = styled.button`
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  gap: 10px;
  border: transparent;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  border-radius: 16px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: -60px;
  visibility: ${({$isVisible}) => ($isVisible ? 'visible' : 'hidden')};
  cursor: pointer;
`

const TitleBlock = styled.div`
  display: flex;
  position: relative;
`

const Button = styled.button`
  background: var(--button, linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%));
  width: 92px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  border-radius: 16px;
  color: #fff;
  font-weight: 600;
  ${({$primary}) => $primary && `
  border: 2px solid var(--button, #EDA075);
  color: #D07B4B;
  background: none;
`}
`


