import {Heading, Title} from "../UI/globalStyles";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {fetchUsefulInfo} from "../store/main/mainAction";
import {useNavigate} from "react-router-dom";

const Informations = () => {
  const info = useSelector(state => state.mainSlice.usefulInfo)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsefulInfo())
  }, [])

  return (
    <>
      <InfoBlock>
        <Heading>{t('helpfulInformation')}</Heading>
        <InformationList>
          {
            info?.map(item => (
              <Info key={item.id} onClick={e => {
                e.preventDefault()
                navigate('/file', {state: {file: item.pdf_file_url}})
              }}>
                <Title>{item.title}</Title>
              </Info>
            ))
          }
        </InformationList>
      </InfoBlock>
    </>
  );
};

export default Informations;

const InfoBlock = styled.div`
  margin-top: 32px;
`

const InformationList = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: 10px;
`

const Info = styled.a`
  padding: 14px 0;
  color: black;
  text-decoration: none;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #F4F5F7;
  }
`