import React, {useEffect, useRef} from 'react';
import {QrReader} from "react-qr-reader";
import {useDispatch} from "react-redux";
import {signIn} from "../store/main/mainAction";
import Icon from "../assets/Icons/Icon";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Flex} from "../UI/globalStyles";
import axios from "axios";

const QrScan = () => {
  const dispatch = useDispatch()
  const ref = useRef()
  const {state} = useLocation()
  const navigate = useNavigate()
  const {t} = useTranslation()

  const handleResult = (result, error) => {
    if (result) {
      if (state?.auth) {
        dispatch(signIn(result?.text)).unwrap().then(() => navigate('/'))
        return
      }
      if (state?.phonebook) {
        axios(`https://vp.ambassador.kg/api/v1/info/equipment-pdf/${result.text}/`).then(({data: {pdf_file_url}}) => {
          navigate('/file', {state: {file: pdf_file_url}, replace: true})
        })
      }
    } else {
      console.log(error)
    }
  }

  useEffect(() => {
    ref.current.style.borderWidth = `${window.innerHeight / 2 - 120 + 'px'} ${window.innerWidth / 2 - 120 + 'px'}`
  }, [])

  return (
    <>
      <Button onClick={() => navigate(-1)}>
        <Flex gap='10px'>
          <Icon id='whiteArrow'/>
          <Back>{t('back')}</Back>
        </Flex>
      </Button>
      <Title>{t('scanDescription')}</Title>
      <Layer ref={ref}>
        <Border id="border"/>
        <Border id="border" />
        <Border id="border"/>
        <Border id="border"/>
      </Layer>
      <QrReader
        onResult={handleResult}
        videoContainerStyle={{position: 'static', padding: 0}} constraints={{facingMode: 'environment'}}
        scanDelay={500}
      />
    </>
  );
};

export default QrScan;


const Title = styled.p`
  color: white;
  position: absolute;
  z-index: 2;
  top: calc(50% - 200px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  max-width: 330px;
`

const Layer = styled.div`
  inset: 0;
  z-index: 1;
  border: 0 solid rgba(0, 0, 0, 0.4);
  position: fixed;
`

const Border = styled(Icon)`
  width: 24px;
  height: 19px;
  position: absolute;
  
  &:first-child {
    top: -7px;
    left: -7px;
  }
  
  &:nth-child(2) {
    right: -7px;
    top: -7px;
    transform: scaleX(-1);
  }

  &:nth-child(3) {
    bottom: -7px;
    left: -7px;
    transform: scaleX(-1) rotate(180deg);
  }

  &:nth-child(4) {
    bottom: -7px;
    right: -7px;
    transform: rotate(180deg);
  }
`

const Button = styled.button`
  border: transparent;
  background: none;
  position: absolute;
  z-index: 2;
  color: #fff;
  cursor: pointer;
  top: 12px;
  font-size: 16px;
  font-weight: 600;
`

const Back = styled.span`
  color: #fff;
`

