import {useLocation, useNavigationType} from "react-router-dom";
import {useEffect, useState} from "react";

export const useCanGoBack = () => {
    const [stack, setStack] = useState([]);
    const { pathname } = useLocation();
    const type = useNavigationType();

    useEffect(() => {
        return () => {
            localStorage.setItem('stack', JSON.stringify(stack))
        }
    }, [stack])

    useEffect(() => {
        setStack(JSON.parse(localStorage.getItem('stack')))
    }, [])

    useEffect(() => {
        if (type === 'POP') {
            setStack(stack.slice(0, stack.length - 1));
        } else if (type === 'PUSH') {
            setStack([...stack, pathname]);
        } else {
            setStack([...stack.slice(0, stack.length - 1), pathname]);
        }
    }, [pathname, type]);

    return !!stack.length;
}

export const useOutsideClick = (ref, handler) => {
    useEffect(() => {
        const listener = event => {
            if (Array.isArray(ref)) {
                const refs = ref.filter(item => {
                    if (item) return !!item.current || !!item;
                    return false;
                });
                const isClickOnEl = refs.some(item => {
                    if (item.current) return item.current.contains(event.target);
                    return item.contains(event.target);
                });
                if (isClickOnEl) return;
                handler(event);
                return;
            }
            if (!ref.current || ref.current.contains(event.target)) return;
            handler(event);
        };
        document.addEventListener('mouseup', listener);

        return () => {
            document.removeEventListener('mouseup', listener);
        };
    }, [ref, handler]);
};
