import styled from "styled-components";

export const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
`

export const Title = styled.span`
  font-weight: ${({fw}) => fw || '500'};
  font-size: ${({fs}) => fs || '16px'};
  text-align: ${({ta}) => ta || 'left'};
  margin-top: ${({mt}) => mt || '0'};
  margin-bottom: ${({mb}) => mb || '0'};
  display: block;
`

export const FlexScroll = styled.div`
  display: flex;
  overflow-x: auto;
  column-gap: 8px;
  &::-webkit-scrollbar {
    opacity: 0;
    height: 1px;
  }
`

export const Flex = styled.div`
  display: flex;
  flex-direction: ${({direction}) => direction || 'row'};
  gap: ${({gap}) => gap || '10px'};
  margin-top: ${({mt}) => mt || '0'};
  margin-bottom: ${({mb}) => mb || '0'};
  justify-content: ${({jc}) => jc || 'flex-start'};
  align-items: ${({ai}) => ai || 'stretch'};
  flex-grow: ${({fg}) => fg || '0'};
  width: ${({width}) => width || 'auto'};
`