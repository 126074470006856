import DynamicHeader from "../components/DynamicHeader";
import {Checkbox, DatePicker, Loader} from "../components";
import React, {useEffect, useRef, useState} from "react";
import {Flex, Title} from "../UI/globalStyles";
import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {useOutsideClick} from "../hooks";
import UiButton from "../UI/UiButton";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {callCleaning} from "../store/service/serviceAction";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {toMain} from "../store/service/serviceSlice";
import {getDate} from "../utils";
import api from "../request";

const Cleaning = () => {
   const success = useSelector(state => state.serviceSlice.success)
   const loading = useSelector(state => state.serviceSlice.loading)
   const [selectedDate, setSelectedDate] = useState({date: '', time: '', isoDate: ''})
   const [showSelect, setShowSelect] = useState(false)
   const [selectedTypes, setSelectedTypes] = useState([])
   const [types, setTypes] = useState([])
   const [errors, setErrors] = useState({date: '', types: ''})
   const dispatch = useDispatch()
   const ref = useRef()
   const {t} = useTranslation()
   const navigate = useNavigate()
   useOutsideClick(ref, () => setShowSelect(false))

   const handleSelect = (type) => {
      let types = [...selectedTypes]

      if (types.some(item => item.id === type.id)) {
         types = types.filter((item => item.id !== type.id))
      } else {
         types.push(type)
      }
      setSelectedTypes(types)
   }

   const checkout = () => {
      if (!selectedDate.date && !selectedTypes.length) {
         return setErrors({date: t('chooseDuration'), types: t('chooseCleaningTypes')})
      }
      if (!selectedDate.date) {
         return setErrors({...errors, date: t('chooseDuration')})
      }
      if (!selectedTypes.length) {
         return setErrors({...errors, types: t('chooseCleaningTypes')})
      }
      const data = {
         day: selectedDate.isoDate,
         date_time: selectedDate.time,
         cleaning_category: selectedTypes.map(({id}) => id)
      }
      dispatch(callCleaning(data))
   }

   const navigateToMain = () => {
      dispatch(toMain())
      navigate('/')
   }

   useEffect(() => {
      api('cleaning/cleaning-category').then(({data}) => setTypes(data))
      return () => {
         dispatch(toMain())
      }
   }, [])

   useEffect(() => {
      if(selectedDate.date || selectedTypes.length) {
         setErrors({types: '', date: '' })
      }
   }, [selectedDate, selectedTypes])

   if(loading) {
      return <Loader/>
   }
   return (
      <Content>
         <DynamicHeader>{t('cleaning')}</DynamicHeader>
         <Flex direction='column' fg='1'>
            {
               !success ? (
                  <>
                     <Title fs='14px' mt='20px' mb='5px'>{t('dateAndTime')}</Title>
                     <RelativeContainer>
                        <DatePicker selectTime={true} selectedDate={selectedDate} setSelectedDate={setSelectedDate} error={errors.date}/>
                        <Label>{errors.date}</Label>
                     </RelativeContainer>
                     <Title fs='14px' mb='5px'>{t('cleaningType')}</Title>
                     <InputContainer ref={ref}>
                        <RelativeContainer>
                           <SelectInput $error={errors.types} onClick={() => setShowSelect(!showSelect)}>
                              <GrayText $selected={selectedTypes}>{selectedTypes.map(({title}) => title).join(', ') || t('selectCleaningType')}</GrayText>
                              <div style={{minWidth: '16px', minHeight: '16px'}}>
                                 <ArrowBtn $open={showSelect} id='arrow' width='16px' height='16px'/>
                              </div>
                           </SelectInput>
                           <Label>{errors.types}</Label>
                        </RelativeContainer>
                        {
                           showSelect && (
                              <DurationContainer>
                                 {
                                    types.map((item, index) => (
                                       <div key={index} onClick={() => handleSelect(item)} style={{cursor: 'pointer'}}>
                                          {index !== 0 && <DividerLine/>}
                                          <div style={{display: 'flex', gap: 8, alignItems: 'center'}}>
                                             <Checkbox checked={selectedTypes.some(({id}) => item.id === id)} />
                                             <Title fs='14px'>{item.title}</Title>
                                          </div>
                                       </div>
                                    ))
                                 }
                              </DurationContainer>
                           )
                        }
                     </InputContainer>
                     <Flex direction='column' gap='20px' mt='auto' mb='20px'>
                        <PriceBlock>
                           <GrayTitle>{t('cost')}</GrayTitle>
                           <GoldenText>{t('free')}</GoldenText>
                        </PriceBlock>
                        <UiButton handleClick={checkout}>
                           <Flex gap='10px' jc='center'>
                              <Title fs='16px'>{t('placeAnOrder')}</Title>
                              <Title fw='700'>{t('free')}</Title>
                           </Flex>
                        </UiButton>
                     </Flex>
                  </>
               ) : (
                  <>
                     <Receipt>
                        <Title ta='center'>{t('successOrderDescription')}</Title>
                        <DividerLine/>
                        <Flex direction='column' gap='12px'>
                           <OrderedDate>{getDate()}</OrderedDate>
                           <Flex jc='space-between'>
                              <Title fs='14px'>{t('date')}</Title>
                              <GoldenText $fs='14px'>{selectedDate.date}</GoldenText>
                           </Flex>
                           <Flex jc='space-between'>
                              <Title fs='14px'>{t('time')}</Title>
                              <GoldenText $fs='14px'>{selectedDate.time}</GoldenText>
                           </Flex>
                           <Flex jc='space-between'>
                              <Title fs='14px'>{t('cleaningType')}</Title>
                              <GoldenText $fs='14px'>{selectedTypes.map(({title}) => title).join(', ')}</GoldenText>
                           </Flex>
                           <DividerLine/>
                           <Flex jc='space-between'>
                              <Total>{t('total')}: </Total>
                              <GoldenText fs='16px'>{t('free')}</GoldenText>
                           </Flex>
                        </Flex>
                     </Receipt>
                     <div style={{margin: 'auto 0 20px'}}>
                        <UiButton handleClick={navigateToMain}>
                           <Title ta='center' fs='16px'>{t('toMain')}</Title>
                        </UiButton>
                     </div>
                  </>
               )
            }

         </Flex>
      </Content>
   );
};

export default Cleaning;

const InputContainer = styled.div`
  position: relative;
`

const SelectInput = styled.div`
  border-radius: 10px;
  background: #F4F5F7;
  min-height: 44px;
  padding: 3px 15px;
  cursor: pointer;
  color: #C0C0CF;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 14px;
  user-select: none;
  justify-content: space-between;
  border: ${({$error}) => $error ? '1px solid red' : 'none'};
`

const ArrowBtn = styled(Icon)`
  margin-left: auto;
  transform: ${({$open}) => $open ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const GrayText = styled.span`
  font-size: ${({$fs}) => $fs || '14px'};
  //color: ${({$selected}) => $selected ? '#000' : '#C0C0CF'}
  color: #000;
`

const DurationContainer = styled.div`
  position: absolute;
  width: 100%;
  margin: -10px 0 20px;
  border-radius: 12px;
  border: 1px solid #E7E6E6;
  background: #fff;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  user-select: none;
`

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F4F5F7;
  margin-bottom: 12px;
`

const GoldenText = styled.p`
  font-size: ${({$fs}) => $fs || '16px'};
  font-weight: 700;
  color: #D07B4B;
  text-align: end;
`

const GrayTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-light);
`

const PriceBlock = styled.div`
  border-radius: 16px;
  background: var(--background-color);
  padding: 0 20px;
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`

const Receipt = styled.div`
  border-radius: 16px;
  border: 1px solid var(--grey, #F4F5F7);
  background: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin-top: auto;
`

const Total = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

const OrderedDate = styled.span`
  font-size: ${({$fs}) => $fs || '14px'};
  color: #C0C0CF;
`

const RelativeContainer = styled.div`
  position: relative;
  padding-bottom: 15px;
`

const Label = styled.span`
  font-size: 13px;
  color: #fa0808;
  position: absolute;
  bottom: -2px;
  left: 5px;
`