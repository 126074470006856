import React from 'react';
import styled from "styled-components";
import Icon from "../assets/Icons/Icon";

export const Checkbox = ({checked}) => {
  return (
    <Root>
      {checked && <Check><Icon id="check" width="11px" height="8px" /></Check>}
    </Root>
  );
};

const Root = styled.div`
  min-width: 18px;
  min-height: 18px;
  background: #F4F5F7;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  position: relative;
`

const Check = styled.div`
  background: linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%);
  border-radius: 4px;
  position: absolute;
  inset: -1px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`
