import {FlexScroll, Heading} from "../UI/globalStyles";
import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import DynamicHeader from "../components/DynamicHeader";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {fetchSliderInfo} from "../store/main/mainAction";
import {Loader} from "../components";
import ReactPlayer from "react-player";
import UiButton from "../UI/UiButton";
import {useTranslation} from "react-i18next";
import {ZoomedImage} from "../components/ZoomedImage";

const icons = ['social_link_youtube', 'social_link_tiktok', 'social_link_instagram', 'social_link_facebook'];
const Venue = () => {
   const dispatch = useDispatch()
   const slider = useSelector(state => state.mainSlice.sliderData)
   const [venue, setVenue] = useState(null)
   const { id } = useParams();
   const navigate = useNavigate();
   const {t} = useTranslation()
   const [zoomImg, setZoomImg] = useState(null)

  const onClick = () => {
    switch (venue.service) {
      case 'DryCleaning':
        navigate('/drycleaning')
        break
      case 'Cleaning':
        navigate('/cleaning')
        break
      case 'Order':
        navigate('/restaurant')
        break
      case 'Spa':
        navigate('/sauna')
        break
      case 'Massage':
        navigate('/massage')
        break
    }
  }

   useEffect(() => {
      dispatch(fetchSliderInfo())
   }, [])

   useEffect(() => {
      if(slider?.length) {
         setVenue(slider.find((item  => String(item.id) === id)))
      }
   }, [slider])

   if (!slider.length || !venue) {
      return <Loader/>
   }

   return (
      <>
         <DynamicHeader>
            {venue.title}
         </DynamicHeader>
         <Heading>{venue.title}</Heading>
         <Description dangerouslySetInnerHTML={{ __html: venue.text }}/>
         <FlexScroll>
            {
               venue.files.map(({file_url}) => (
                  <Card key={file_url} img={file_url} onClick={() => setZoomImg(file_url)}/>
               ))
            }
         </FlexScroll>
         {
            venue.youtube_url && (
               <ReactPlayer className='react-player' url={venue.youtube_url} style={{ margin: '24px auto 0'}}/>
            )
         }
         <Flex>
            {
               icons.map((item, index) => venue[item] ? (
                  <LinkA
                     key={index}
                     href={venue[item]}
                     target='_blank'
                     rel='noopener noreferrer'
                  >
                     <Icon id={item} width='28px' height='28px'/>
                  </LinkA>
               ) : '')
            }
         </Flex>
        <UiButton handleClick={onClick} style={{marginTop: 'auto', marginBottom: 24}}>{t('toOrderService')}</UiButton>
         <ZoomedImage active={zoomImg} setActive={setZoomImg}>
            <ZoomedImg src={zoomImg} alt="venue image"/>
         </ZoomedImage>
      </>
   );
};

export default Venue;

const Description = styled.p`
  font-size: 14px;
  margin: 10px 0 24px;
`

const Card = styled.div`
  width: 280px;
  height: 160px;
  border-radius: 20px;
  background: ${({img}) => `url(${img})`};
  background-size: cover;
  flex-shrink: 0;
  cursor: pointer;
`

const LinkA = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: var(--primary-color);
  width: 51px;
  height: 51px;
`

const Flex = styled.div`
  display: flex;
  column-gap: 12px;
  margin-top: 24px;
  margin-bottom: 30px;
`

const ZoomedImg = styled.img`
  border-radius: 8px;
`