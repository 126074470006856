import {Places} from "../components";
import Services from "../components/Services";
import Equipment from "../components/Equipment";
import Informations from "../components/Informations";

const Home = () => {
    return (
        <>
            <Places/>
            <Services/>
            <Equipment/>
            <Informations/>
        </>
    );
};

export default Home;