import React, {useMemo} from 'react';
import {Title} from "../UI/globalStyles";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const PaymentMethods = ({selectedPayment, setSelectedPayment, cashSum, setCashSum}) => {
   const {t} = useTranslation()

   const paymentMethods = useMemo(() => {
      return [{
         title: t('cash'), id: 'cash'
      }, {
         title: t('payByCard'), id: 'payment by card'
      }, {
         title: t('writeToNumber'), id: 'to number'
      }]
   },[])

   return (
      <PaymentBlock>
         <Title fs='20px' fw='700'>{t('paymentMethod')}</Title>
         <PaymentTypes>
            {
               paymentMethods.map(item => (
                  <div key={item.id}>
                     <RadioBtn onClick={() => setSelectedPayment(item.id)}>
                        <StyledRadio className={selectedPayment === item.id ? 'active' : ''}/>
                        <Title>{item.title}</Title>
                     </RadioBtn>
                     {item.id === 'cash' && selectedPayment === 'cash' && (
                        <CashBlock>
                           <Span>{t('changeWith')}</Span>
                           <StyledInput value={cashSum}
                                        onChange={(e) => setCashSum(e.target.value)}
                                        type="number" placeholder={t('enterSum')} min={0}
                                        max={100000}/>
                        </CashBlock>
                     )}
                  </div>
               ))
            }
         </PaymentTypes>
      </PaymentBlock>
   );
};

export default PaymentMethods;


const RadioBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: transparent;
  cursor: pointer;
`

const StyledRadio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #F4F5F7;
   display: flex;
   align-items: center;
   justify-content: center;

  &.active {
    background: var(--primary-color);
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #F4F5F7;
    }
  }
`

const StyledInput = styled.input`
  background: var(--background-color);
  padding: 10px 15px;
  border: transparent;
  border-radius: 10px;
  font-size: 14px;
   height: 44px;
   
   &::placeholder {
      color: #C0C0CF;
   }

  &:focus {
    outline: none;
  }
`

const CashBlock = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const Span = styled.span`
  font-size: 12px;
   line-height: 24px;
   margin-top: 6px;
`

const PaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

const PaymentTypes = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0 10px;
   
   & > div:nth-child(2) {
      padding: 16px 0;
      margin: 16px 0;
      border: 1px solid #F4F5F7;
      border-right: none;
      border-left: none;
   }
`


