import {useEffect, useState} from 'react';
import DynamicHeader from "../components/DynamicHeader";
import styled from "styled-components";
import FoodCard from "../components/FoodCard";
import {BottomSheet} from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'
import UiButton from "../UI/UiButton";
import {Flex, Title} from "../UI/globalStyles";
import Icon from "../assets/Icons/Icon";
import {useDispatch, useSelector} from "react-redux";
import {addToCart} from "../store/kitchen/kitchenSlice";
import {fetchCategories, fetchFoods} from "../store/kitchen/kitchenAction";
import {useTranslation} from "react-i18next";
import {Loader} from "../components";
import {ZoomedImage} from "../components/ZoomedImage";

const Restaurant = () => {
    const {storageFoods, foods, categories} = useSelector(state => state.kitchenSlice)
    const dispatch = useDispatch()
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedFood, setSelectedFood] = useState({})
    const [quantity, setQuantity] = useState(1)
    const [sum, setSum] = useState(0)
    const [alert, setAlert] = useState(false)
    const {t} = useTranslation()
    const [zoomImg, setZoomImg] = useState(null)
    const openSheet = (food) => {
        setSelectedFood(food)
        setSum(food.price)
        setIsOpen(true)
    }

    const closeSheet = () => {
        setIsOpen(false)
        setSelectedFood({})
        setQuantity(1)
    }

    const inc = () => {
        if (quantity < 10) {
            setQuantity(quantity + 1)
            setSum(selectedFood?.price * (quantity + 1))
        }
    }

    const dec = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
            setSum(sum - selectedFood?.price)
        }
    }
    useEffect(() => {
        if(isOpen) {
            const element = document.querySelector('[data-rsbs-header]');
            console.log(element)
        }
    }, [isOpen])

    useEffect(() => {
        if (selectedFood) {
            localStorage.setItem('foods', JSON.stringify(storageFoods))
        }
    }, [storageFoods])

    useEffect(() => {
        dispatch(fetchFoods(selectedCategory))
    }, [selectedCategory])

    useEffect(() => {
        dispatch(fetchCategories())
    }, [])

    useEffect(() => {
        if (alert) {
            setTimeout(() => {
                setAlert(false)
            }, 2000)
        }
    }, [alert])

    const appendToCart = () => {
        const data = {
            count: quantity,
            totalPrice: sum,
            ...selectedFood
        }
        dispatch(addToCart(data))
        setAlert(true)
        closeSheet();
    }

    if(!foods || !categories) {
        return <Loader/>
    }

    return (
        <div>
            <DynamicHeader>{t('restaurant')}</DynamicHeader>
            <Categories>
                <Category onClick={() => setSelectedCategory(null)} $active={selectedCategory === null}>{t('all')}</Category>
                {
                    categories.map(category => (
                        <Category key={category.id} onClick={() => setSelectedCategory(category.id)} $active={selectedCategory === category.id}>{category.name}</Category>
                    ))
                }
            </Categories>
            {
                alert && <Alert>{t('addedToCart')}</Alert>
            }
            <Foods>
                {
                    foods.map(item => (
                        <div key={item.id} onClick={() => openSheet(item)} style={{width: '100%'}}>
                            <FoodCard {...item}/>
                        </div>
                    ))
                }

                <BottomSheet onDismiss={closeSheet} open={isOpen} initialFocusRef={false}>
                    <ImgBlock radius='100%'>
                        <ImgBlock radius='57%'>
                            <FoodImg src={selectedFood.image} onClick={() => setZoomImg(selectedFood.image)}/>
                        </ImgBlock>
                    </ImgBlock>
                    <SheetContent>
                        <FoodTitle>{selectedFood.name}</FoodTitle>
                        <CompoundText>{selectedFood.desc}</CompoundText>
                        <AdditionalInf>
                            <Weight>{selectedFood.gram} {t('gram')}.</Weight>
                            <Price>{selectedFood.price} с</Price>
                        </AdditionalInf>
                        <Flex mt='16px'>
                            <Counter>
                                <CountBtn onClick={dec}>
                                    <Icon id='minus'/>
                                </CountBtn>
                                <Quantity>{quantity}</Quantity>
                                <CountBtn onClick={inc}>
                                    <Icon id='plus'/>
                                </CountBtn>
                            </Counter>
                            <UiButton handleClick={appendToCart}>
                                <Flex ai='center' jc='center'>
                                    <Title fs='16px' fw='600'>{t('toCart')}</Title>
                                    <Title fw='800' fs='16px'>{sum} с</Title>
                                </Flex>
                            </UiButton>
                        </Flex>
                    </SheetContent>
                </BottomSheet>
            </Foods>
            <ZoomedImage active={zoomImg} setActive={setZoomImg}>
                <ZoomedImg src={zoomImg} alt="Food Image"/>
            </ZoomedImage>
        </div>
    );
};

export default Restaurant;

const Categories = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Category = styled.button`
  border: transparent;
  border-radius: 16px;
  padding: 8px 15px;
  background: ${({$active}) => $active ? 'var(--primary-color)' : 'var(--background-color)'};
  color: ${({$active}) => $active ? 'var(--secondary-text-color)' : 'var(--text-color)'};
  min-height: 36px;
  line-height: 20px;
  font-size: 14px;
`

const Foods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 20px;
  align-items: flex-start;
`
const SheetContent = styled.div`
  padding: 20px;
`

const FoodImg = styled.img`
  max-width: 140px;
  max-height: 140px;
  height: auto;
  width: auto;
`

const FoodTitle = styled.h3`
  font-size: 20px;
`

const CompoundText = styled.p`
  font-weight: 500;
  color: var(--text-light);
  margin-top: 12px;
`

const AdditionalInf = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  padding: 20px 0;
  border-top: 1px solid #F4F5F7;
  border-bottom: 1px solid #F4F5F7;
`

const Price = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #D07B4B;
`

const Weight = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: var(--text-light);
`

const CountBtn = styled.button`
  background: none;
  border: transparent;
  display: flex;
  align-items: center;
`

const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 15px 20px;
  background: var(--background-color);
  border-radius: 16px;
`

const Quantity = styled.span`
  font-size: 14px;
  font-weight: 600;
`

const ImgBlock = styled.div`
  width: ${({radius}) => radius};
  border-radius: 100%;
  background: linear-gradient(0deg, #FAFAFA 0.13%, rgba(250, 250, 250, 0.00) 47.93%);
  box-shadow: 0px -6px 4px 0px rgba(165, 165, 186, 0.07) inset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 20px;
`

const Alert = styled.div`
  border-radius: 10px;
  width: 100%;
  background: #D2F5D2;
  padding: 15px;
  font-weight: 500;
  margin-top: 17px;
  font-size: 14px;
  border: 1px solid #83EB96;
`

const ZoomedImg = styled.img`
  border-radius: 8px;
`