import {configureStore} from "@reduxjs/toolkit";
import mainSlice from "./main/mainSlice";
import kitchenSlice from "./kitchen/kitchenSlice";
import serviceSlice from "./service/serviceSlice";

const store = configureStore({
    reducer: {
        mainSlice,
        kitchenSlice,
        serviceSlice
    }
})

export default store;