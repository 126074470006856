import styled from "styled-components";
import Language from "./Language";
import Icon from "../assets/Icons/Icon";
import {Flex} from "../UI/globalStyles";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toMain} from "../store/service/serviceSlice";

const DynamicHeader = ({children}) => {
   const {storageFoods} = useSelector(({kitchenSlice}) => kitchenSlice)
   const isAuth = useSelector(state => state.mainSlice.isAuth)
   const totalCount = storageFoods.reduce((total, food) => total + food.count, 0)
   const {pathname} = useLocation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const goBack = () => {
      dispatch(toMain())
      navigate(-1)
   }
   return (
      <Header>
         <BackBtn onClick={goBack}>
            <Back id='arrow'/>
         </BackBtn>
         <Title>{children}</Title>
         <Flex gap='8px'>
            {
               pathname === '/restaurant' && isAuth ? (
                  <Link to='/cart'>
                     <Cart>
                        <Icon id='cart' width='24px' height='24px'/>
                        {!!totalCount && <Quantity>{totalCount}</Quantity>}
                     </Cart>
                  </Link>
               ) : ''
            }
            <Language/>
         </Flex>
      </Header>
   );
};

export default DynamicHeader;

const Header = styled.div`
  position: sticky;
  width: calc(100% + 40px);
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #F4F5F7;
  justify-content: space-between;
  top: 0;
  max-width: 600px;
  background: #fff;
  z-index: 2;
  margin: 0 -20px;
  padding: 0 20px;
`

const Back = styled(Icon)`
  transform: rotate(90deg);
  cursor: pointer;
`

const Title = styled.span`
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const BackBtn = styled.button`
  background: none;
  border: transparent;
`

const Cart = styled.button`
  background: none;
  border: transparent;
  position: relative;
`

const Quantity = styled.span`
  font-size: 9px;
  background: var(--primary-color);
  color: var(--secondary-text-color);
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -2px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
