import styled from "styled-components";
import UiButton from "../UI/UiButton";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {sendMessageToManagement} from "../store/main/mainAction";
import {Loader, Modal} from "../components";
import {Flex, Title} from "../UI/globalStyles";
import Icon from "../assets/Icons/Icon";

const Management = () => {
   const {t} = useTranslation()
   const dispatch = useDispatch()
   const loading = useSelector(state => state.mainSlice.loading)
   const [message, setMessage] = useState('')
   const [activeModal, setActiveModal] = useState(false)
   const [error, setError] = useState('')
   const onChange = (e) => {
      e.target.style.height = 0
      e.target.style.height = e.target.scrollHeight + 'px'
      setMessage(e.target.value)
      setError('')
   }

   const sendMessage = () => {
      if (!message) {
         return setError(t('writeMessage'))
      }
      dispatch(sendMessageToManagement(message)).unwrap().then(() => {
         setActiveModal(true)
         setMessage('')
      })
   }

   return (
      <div>
         <Span>{t('message')}</Span>
         <Container>
            <TextArea $error={error} value={message} onChange={onChange} placeholder={t('enterMessage')}></TextArea>
            <Label>{error}</Label>
         </Container>
         <UiButton disabled={loading} handleClick={sendMessage}>{t('send')}</UiButton>
         <Modal active={activeModal} setActive={setActiveModal}>
            <Flex direction='column' ai='center' gap='12px'>
               <Icon id='successv2' width='80px' height='80px'/>
               <Title fs='20px' fw='700' ta='center'>{t('messageSent')}</Title>
               <Title ta={'center'}>{t('thxForFeedback')}</Title>
            </Flex>
         </Modal>
      </div>
   );
};

export default Management;

const TextArea = styled.textarea`
  padding: 10px 15px;
  background: #F4F5F7;
  outline: none;
  width: 100%;
  min-height: 100px;
  font-size: 14px;
  border-radius: 10px;
  resize: none;
  border: ${({$error}) => $error ? '1px solid red' : 'none'};
`

const Span = styled.span`
  font-size: 14px;
`

const Label = styled.span`
  font-size: 13px;
  color: #fa0808;
  position: absolute;
  bottom: 5px;
  left: 5px;
`

const Container = styled.div`
  position: relative;
  padding-bottom: 15px;
`