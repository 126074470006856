import {createAsyncThunk} from "@reduxjs/toolkit";
import api from "../../request";

export const fetchFoods = createAsyncThunk(
  'kitchenSlice/fetchFoods',
  async (category) => {
    try {
      const url = category ? `/kitchen/food/?category_id=${category}` : '/kitchen/food/'
      return (await api.get(url)).data
    } catch (error) {
      console.log(error)
    }
  }
)

export const orderFoods = createAsyncThunk(
  'kitchenSlice/orderFoods',
  async ({data, method},) => {
    try {
      await Promise.all(data.map(item => api.post('kitchen/cart/', {food: item.id, amount: item.count})))
      await api.post('kitchen/order/', method)
      localStorage.removeItem('foods')
      localStorage.setItem('takeOffDishTime', (Date.now() + (30 * 60 * 1000)).toString())
    } catch (error) {
      console.log(error)
    }
  }
)

export const fetchCategories = createAsyncThunk(
  'kitchenSlice/fetchCategories',
  async () => {
    try {
      return (await api.get('/kitchen/category/')).data
    } catch (error) {
      console.log(error)
    }
  }
)

export const cleanTheDishes = createAsyncThunk(
  'kitchenSlice/cleanTheDishes',
  async () => {
    try {
      return await api.get('/kitchen/order/posuda')
    } catch (error) {
      console.log(error)
    }
  }
)