import React from 'react';
import ReactDOM from 'react-dom/client';
import {createGlobalStyle} from "styled-components";
import {Provider} from "react-redux";
import App from './App';
import store from "./store/store";
import './assets/font/stylesheet.css'
import './index.css';
import {BrowserRouter} from "react-router-dom";

const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
  }

  :root {
    --primary-color: linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%);
    --secondary-color: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%);
    --background-color: #F4F5F7;
    --text-color: #000;
    --secondary-text-color: #fff;
    --text-light: #7E7E7E;
    --rsbs-max-w: 600px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
    --rsbs-overlay-rounded: 23px;
  }
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <Provider store={store}>
                <App/>
                <Global/>
            </Provider>
        </BrowserRouter>
    </>
);


