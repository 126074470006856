import React from 'react';
import {useLocation} from "react-router-dom";
import DynamicHeader from "../components/DynamicHeader";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

export const FileView = () => {
  const {state} = useLocation()
  return (
    <>
      <DynamicHeader/>
      <div style={{height: 20}}/>
      <div style={{margin: '0 -20px'}}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <Viewer fileUrl={state.file} defaultScale="PageWidth"/>
        </Worker>
      </div>
    </>
  );
};
