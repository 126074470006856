import {NavLink, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import Icon from "../assets/Icons/Icon";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Loader} from "./index";

const Navbar = ({count}) => {
  const {t} = useTranslation()
  const isAuth = useSelector(state => state.mainSlice.isAuth)
  const [chatReloading, setChatReloading] = useState(false)
  const location = useLocation()
  const navigationItems = useMemo(() => {
    return [
      {path: '/', text: t('main'), iconId: 'home'},
      {path: '/notifications', text: t('notifications'), iconId: 'notification'},
      {path: '/chat', text: t('chat'), iconId: 'chat'},
      {path: '/management', text: t('guideline'), iconId: 'guide'}
    ];
  }, [])

  const createBtn = () => {
    const el = document.createElement('div')
    el.textContent = t('chooseDepartment')
    el.style.position = 'absolute'
    el.style.fontSize = '20'
    el.style.color = 'white'
    el.style.fontWeight = '500'
    el.style.display = 'flex'
    el.style.alignItems = 'center'
    el.style.justifyContent = 'center'
    el.style.zIndex = 10
    el.style.right = 0
    el.style.top = 0
    el.style.width = '150px'
    el.style.cursor = 'pointer'
    el.style.height = window.mobileAndTabletCheck() ? '70px' : '100%'
    el.style.borderRadius = '6px 24px 0 0'
    return el
  }

  useEffect(() => {
    window.setBtn = () => {
      const btn = createBtn()
      btn.addEventListener('click', () => {
        setChatReloading(true)
        window.jivo_api.clearHistory()
        window.jivo_destroy()
        window.jivo_init()
        const open = () => {
          setTimeout(() => {
            try {
              window.jivo_api?.open()
            } catch (e) {
              open()
            }
          }, 500)
          setTimeout(() => {
            setChatReloading(false)
          }, 900)
        }
        open()
      })
      if (window.mobileAndTabletCheck()) {
        setTimeout(() => {
          document.body.querySelector('jdiv > jdiv').firstChild.appendChild(btn)
        }, 600)

        return
      }
      document.getElementById('jivo_action').append(btn)
    }

  }, [])

  const onChatClick = (e) => {
    if (isAuth) {
      e.preventDefault()
      window.jivo_api?.open()
      setTimeout(() => {
        window.setBtn()
      }, 0)
    }
  }
  if (chatReloading) return <Loader/>

  return (
    <Root $hide={location.pathname !== '/' && !isAuth}>
      {navigationItems.map(({path, text, iconId}) => (
        <NavItem
          key={path}
          to={path}
          exact={String(path === '/')}
          {...(path === '/chat' && {onClick: onChatClick})}
        >
          {({isActive}) => (
            <>
              <Container>
                <Icon id={iconId} fill={isActive ? '#EDA075' : '#7E7E7E'}/>
                {
                  path === '/notifications' && count ? <NotifCount>{count}</NotifCount> : ''
                }
              </Container>
              <span>{text}</span>
            </>
          )
          }
        </NavItem>
      ))}
    </Root>

  );
};

export default Navbar;

const Root = styled.nav`
  position: sticky;
  bottom: 0;
  max-width: 600px;
  width: 100%;
  height: 60px;
  display: ${({$hide}) => $hide ? 'none' : 'flex'};
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #F4F5F7;
`;

const NavItem = styled(NavLink)`
  text-decoration: none;
  padding: 5px 10px;
  color: #000;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: center;
  gap: 5px;

  &.active {
    color: #EDA075;
  }
`;

const Container = styled.div`
  position: relative;
`

const NotifCount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  font-size: 9px;
  background: #EDA075;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`
