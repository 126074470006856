import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {Flex} from "../UI/globalStyles";
import {useDispatch} from "react-redux";
import {decFoodInCart, deleteFromCard, incFoodInCart} from "../store/kitchen/kitchenSlice";
import {useTranslation} from "react-i18next";

const FoodCard = ({name, desc, gram, price, image, count, totalPrice, id}) => {
   const dispatch = useDispatch()
   const {t} = useTranslation()
   const dec = () => {
      if (count > 1) {
         dispatch(decFoodInCart(id))
      }
   }

   const inc = () => {
      if (count < 10) {
         dispatch(incFoodInCart(id))
      }
   }

   return (
      <Card>
         <Image
            src={image}/>
         <Description>
            <Flex jc='space-between'>
               <Title>{name}</Title>
               {
                  count && (
                     <DeleteBtn onClick={() => dispatch(deleteFromCard(id))}>
                        <Icon id='delete'/>
                     </DeleteBtn>
                  )
               }
            </Flex>
            <Compound>{desc}</Compound>
            {
               count ? (
                  <AdditionalInf>
                     <Counter>
                        <CountBtn onClick={dec}>
                           <Icon id='minusCart'/>
                        </CountBtn>
                        <Quantity>{count}</Quantity>
                        <CountBtn onClick={inc}>
                           <Icon id='plusCart'/>
                        </CountBtn>
                     </Counter>
                     <Flex direction='column' gap='0'>
                        <Compound>{count} x <SmallPrice>{price}</SmallPrice></Compound>
                        <Price>{totalPrice} с</Price>
                     </Flex>
                  </AdditionalInf>
               ) : (
                  <AdditionalInf>
                     <Weight>{gram} {t('gram')}.</Weight>
                     <Price>{price} с</Price>
                  </AdditionalInf>
               )
            }
         </Description>
      </Card>
   );
};

export default FoodCard;

const Card = styled.div`
  padding: 10px 12px;
  display: flex;
  border: 1px solid #F4F5F7;
  background: #FFF;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  border-radius: 16px;
  gap: 12px;
  cursor: pointer;
  width: 100%;
  min-height: 130px;
`

const Description = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const Title = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-top: 4px;
`

const Compound = styled.div`
  color: var(--text-light);
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 12px;
`

const AdditionalInf = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto 0 4px;
`

const Price = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #D07B4B;
`

const Weight = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: var(--text-light);
`

const Image = styled.img`
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
  align-self: center;
`

const CountBtn = styled.button`
  background: none;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: #FFF2EA;
  border-radius: 16px;
`

const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  border-radius: 16px;
`

const Quantity = styled.span`
  font-size: 14px;
  font-weight: 600;
`

const SmallPrice = styled.span`
  font-weight: 600;
  color: #D07B4B;
`

const DeleteBtn = styled.button`
  background: none;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`