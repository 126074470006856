import Icon from "../assets/Icons/Icon";
import styled from "styled-components";
import {Heading, Title} from "../UI/globalStyles";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchRoomEquipmentInfo} from "../store/main/mainAction";

const Equipment = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const directories = useSelector(state => state.mainSlice.equipmentInfo)

  useEffect(() => {
    dispatch(fetchRoomEquipmentInfo())
  }, [])

  return (
    <EquipmentBlock>
      <Heading>{t('equipment')}</Heading>
      <EquipmentList>
        {
          directories?.map(item => (
            <LinkA key={item.id} to='/phonebook'>
              <Directory>
                <QrIcon>
                  <Icon id='qr' fill='#fff' width='24px' height='24px'/>
                </QrIcon>
                <Title>{item.title}</Title>
              </Directory>
            </LinkA>
          ))
        }
      </EquipmentList>
    </EquipmentBlock>
  );
};

export default Equipment;

const EquipmentBlock = styled.div`
  margin-top: 20px;
`

const EquipmentList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  list-style: none;
  margin-top: 20px;
`

const Directory = styled.li`
  display: flex;
  align-items: center;
  column-gap: 12px;
  cursor: pointer;
`

const QrIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--primary-color);
  width: 51px;
  height: 51px;
`

const LinkA = styled(Link)`
  text-decoration: none;
  color: var(--text-color)
`