import React, {useEffect, useMemo, useRef, useState} from 'react';
import DynamicHeader from "../components/DynamicHeader";
import {useDispatch, useSelector} from "react-redux";
import FoodCard from "../components/FoodCard";
import styled from "styled-components";
import {Flex, Title} from "../UI/globalStyles";
import UiButton from "../UI/UiButton";
import {orderFoods} from "../store/kitchen/kitchenAction";
import {PaymentMethods} from "../components";
import {resetCart} from "../store/kitchen/kitchenSlice";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Cart = () => {
  const dispatch = useDispatch()
  const {storageFoods, order} = useSelector(({kitchenSlice}) => kitchenSlice)
  const percent = useSelector(({mainSlice}) => mainSlice.percent)
  const totalPrice = storageFoods.reduce((total, food) => total + food.totalPrice, 0)
  const totalPriceWithPercent = Math.round(totalPrice * (1 + percent / 100));
  const sumForService = Math.round(totalPrice * (percent / 100))
  const totalCount = storageFoods.reduce((total, food) => total + food.count, 0)
  const [cashSum, setCashSum] = useState('')
  const [selectedPayment, setSelectedPayment] = useState('cash');
  const isMounted = useRef(false)
  const {t} = useTranslation()
  const paymentMethods = useMemo(() => {
    return [{
      title: t('cash'), id: 'cash'
    }, {
      title: t('payByCard'), id: 'payment by card'
    }, {
      title: t('writeToNumber'), id: 'to number'
    }]
  }, [])
  const selectedIndex = paymentMethods.findIndex((method) => method.id === selectedPayment);

  useEffect(() => {
    if (isMounted.current) {
      localStorage.setItem('foods', JSON.stringify(storageFoods))
    }
    isMounted.current = true
  }, [storageFoods])

  useEffect(() => {
    return () => {
      if (order.success) {
        dispatch(resetCart())
      }
    }
  }, [order])

  const handleOrder = (e) => {
    e.preventDefault()

    const method = {
      payment_type: selectedPayment,
    }
    if (selectedPayment === 'cash') {
      method.change_with = cashSum
    }
    dispatch(orderFoods({data: storageFoods, method}))
  }
  return (
    <Content>
    <DynamicHeader>{t('cart')}</DynamicHeader>
    <Flex direction='column' fg='1'>
      {!order.success ? (
         <>
           <FlexColumn>
             {storageFoods.map(item => (<FoodCard key={item.id} {...item}/>))}
             <GrayText style={{margin: '10px 0 10px auto'}}>{t("service")} {percent}%: <Price style={{fontWeight: '700'}}>{sumForService} с</Price></GrayText>
           </FlexColumn>
           <PriceBlock>
             <GrayText>{t('quantity')}: {totalCount}</GrayText>
             <Total>
               {t('total')}:
               <Price> {totalPriceWithPercent} с</Price>
             </Total>
           </PriceBlock>
           <PaymentBlock onSubmit={handleOrder}>
             <PaymentMethods selectedPayment={selectedPayment} cashSum={cashSum} setCashSum={setCashSum}
                             setSelectedPayment={setSelectedPayment}/>
             <UiButton style={{marginTop: 'auto'}} type="submit" disabled={!totalCount}>
               <Flex jc='center' ai='center'>
                 <Title fw='600'>{t('placeAnOrder')}</Title>
                 <Title fw='800'>{totalPriceWithPercent} с</Title>
               </Flex>
             </UiButton>
           </PaymentBlock>
         </>
      ) : (
         <>
           <Receipt>
             <Title>{t('successOrderDescription')}</Title>
             <DividerLine/>
             <Flex direction='column' gap='12px'>
               <GrayText>{order.date}</GrayText>
               <Foods>
                 {storageFoods.map(({name, price, count, id, image}) => (<OrderedFood key={id}>
                   <OrderedFoodImg src={image}/>
                   <Title fw={400} fs={14}>{name}</Title>
                   <Compound>{count} x <GoldenText>{price} c</GoldenText></Compound>
                 </OrderedFood>))}
               </Foods>
               <DividerLine/>
               <Flex jc='space-between'>
                 <GrayText>{t('service')} {percent}%</GrayText>
                 <GoldenText>{sumForService} c</GoldenText>
               </Flex>
               <DividerLine/>
               <Flex jc='space-between'>
                 <GrayText>{t('paymentMethod')}</GrayText>
                 <GoldenText>{paymentMethods[selectedIndex].title}</GoldenText>
               </Flex>
               <DividerLine/>
               <Flex jc='space-between'>
                 <GrayText>{t('quantity')}: {totalCount}</GrayText>
                 <div>
                   <Total>{t('total')}: </Total>
                   <GoldenText fs='16px'>{totalPriceWithPercent} с</GoldenText>
                 </div>
               </Flex>
             </Flex>
           </Receipt>
           <Link to='/' state={{auth: true}} style={{display: 'block', margin: 'auto 0 20px'}}>
             <UiButton>
               <Flex jc='center' ai='center'>
                 <Title fw='600'>{t('toMain')}</Title>
               </Flex>
             </UiButton>
           </Link>
         </>
      )}
    </Flex>
  </Content>);
};

export default Cart;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  max-height: 70vh;
  overflow-y: auto;
  margin-top: 20px;
`

const PriceBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 16px;
  background: var(--background-color);
`

const Total = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

const Price = styled.span`
  color: #D07B4B;
`

const PaymentBlock = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 24px;
`

const Receipt = styled.div`
  border-radius: 16px;
  border: 1px solid var(--grey, #F4F5F7);
  background: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin-top: auto;
`

const DividerLine = styled.span`
  width: 100%;
  height: 1px;
  background: var(--background-color);
`

const Foods = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderedFood = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 8px 0 0;
  gap: 10px;
`

const OrderedFoodImg = styled.img`
  max-width: 40px;
  max-height: 40px;
`

const Compound = styled.div`
  color: var(--text-light);
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  margin-left: auto;
`

const GoldenText = styled.span`
  font-weight: 600;
  color: #D07B4B;
  font-size: ${({fs}) => fs || '14px'};
`

const GrayText = styled.span`
  color: var(--text-light);
  text-overflow: ellipsis;
  font-size: 14px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`
