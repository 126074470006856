import styled from "styled-components";

const UiButton = ({children, width, handleClick, ...props}) => {
    return (
        <Button onClick={handleClick} $width={width} {...props}>
            {children}
        </Button>
    );
};

export default UiButton;

const Button = styled.button`
  color: var(--secondary-text-color);
  background: var(--primary-color);
  width: ${({width}) => width || '100%'};
  padding: 16px 24px;
  text-align: center;
  border: none;
  border-radius: 16px;
  font-weight: 600;
  cursor: pointer;

  &[disabled] {
    cursor: default;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.30) 100%), linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%);
  }
`