import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getDatabase} from "firebase/database"
const firebaseConfig = {
   apiKey: "AIzaSyAFG4OHQsCEwcfro_s2_-ckW-DPP0hrszE",
   authDomain: "ambassador-9acbb.firebaseapp.com",
   databaseURL: "https://ambassador-9acbb-default-rtdb.asia-southeast1.firebasedatabase.app",
   projectId: "ambassador-9acbb",
   storageBucket: "ambassador-9acbb.appspot.com",
   messagingSenderId: "612101163064",
   appId: "1:612101163064:web:5503686028b07839943ecd",
   measurementId: "G-TGP00P25QM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase()