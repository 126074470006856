import React, {useMemo, useState} from 'react';
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';

const useTime = (selectedDate, addedTime = 0) => {
  return useMemo(() => {
    const hours = []
    const isToday = selectedDate.rawDate ? selectedDate.rawDate.getDate() === new Date().getDate() : true

    const date = new Date(isToday? Date.now() + (addedTime * 60 * 1000) : new Date('2023-01-01T00:00:00'))
    for (let i = date.getHours(); i < 24; i++) {
      hours.push(i)
    }
    const minutes = []
    for (let i = date.getMinutes(); i < 60; i++) {
      minutes.push(i)
    }
    return [hours, minutes]
  }, [selectedDate])
}

const conf = {
  loop: true,
  direction: 'vertical',
  slideToClickedSlide: true,
  centeredSlides: true,
  slidesPerView: 7,
  freeMode: true,
  spaceBetween: 6,
}

export const Timepicker = ({time, setTime, selectedDate, addedTime = 0}) => {
  const [activeHour, setActiveHour] = useState(0)
  const [activeMinute, setActiveMinute] = useState(0)
  const [hours, minutes] = useTime(selectedDate, addedTime)

  const handleHourChange = ({activeIndex, realIndex}) => {
    setActiveHour(activeIndex + 1)
    setTime({...time, hour: hours[realIndex]})
  }

  const handleMinuteChange = ({activeIndex, realIndex}) => {
    setActiveMinute(activeIndex + 1)
    setTime({...time, minute: minutes[realIndex]})
  }

  return (
    <Root>
      <SwiperSlider
        {...conf}
        $activeSlide={activeHour}
        initialSlide={time.hour}
        onSlideChange={handleHourChange}
      >
        {hours.map((v) => (
          <SwiperSlide key={v} style={{paddingLeft: 90}}>{v < 10 ? `0${v}` : v}</SwiperSlide>
        ))}
      </SwiperSlider>
      <SwiperSlider
        {...conf}
        initialSlide={time.minute}
        $activeSlide={activeMinute}
        onSlideChange={handleMinuteChange}
      >
        {minutes.map((v) => (
          <SwiperSlide key={v} style={{paddingRight: 90}}>{v < 10 ? `0${v}` : v}</SwiperSlide>
        ))}
      </SwiperSlider>
    </Root>
  );
};

const Root = styled.div`
  height: 170px;
  display: flex;
  justify-content: center;
  position: relative;

  &::after, &::before {
    content: '';
    display: block;
    width: 100%;
    height: 0.5px;
    background: #CCC;
    position: absolute;
    left: 0;
    top: 50%;
  }

  &::before {
    transform: translateY(calc(-50% - 15px));
  }

  &::after {
    transform: translateY(calc(-50% + 15px));
  }
`

const SwiperSlider = styled(Swiper)`
  height: 100%;
  width: 50%;

  .swiper-slide {
    font-size: 12px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    transition: .2s;

    &:nth-child(${({$activeSlide}) => $activeSlide + 2}), &:nth-child(${({$activeSlide}) => $activeSlide - 2}) {
      font-size: 14px;
      color: #C2C2C2;
    }

    &:nth-child(${({$activeSlide}) => $activeSlide + 3}), &:nth-child(${({$activeSlide}) => $activeSlide - 3}) {
      font-size: 12px;
      color: #D7D7D7;
    }

    &:nth-child(${({$activeSlide}) => $activeSlide + 2}) {
      padding-top: 3px;
    }

    &:nth-child(${({$activeSlide}) => $activeSlide - 2}) {
      padding-bottom: 3px;
    }
  }

  .swiper-slide-active {
    height: 24px;
    font-size: 20px;
  }

  .swiper-slide-prev, .swiper-slide-next {
    color: #AEAEAE;
    font-size: 16px;
  }

  .swiper-slide-prev {
    padding-bottom: 6px;
  }

  .swiper-slide-next {
    padding-top: 6px;
  }
`
