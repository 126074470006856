import { useLocation} from "react-router-dom";
import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import api from "../request";
import Icon from "../assets/Icons/Icon";
import DynamicHeader from "../components/DynamicHeader";
import {useTranslation} from "react-i18next";
import {Flex, Title} from "../UI/globalStyles";
import {getDate} from "../utils";
import {useSelector} from "react-redux";

const NotificationDetail = () => {
    const percent = useSelector(({mainSlice}) => mainSlice.percent)
    const [data, setData] = useState(null)
    const [cleaningCategory, setCleaningCategory] = useState([])
    const [spaCategory, setSpaCategory] = useState([])
    const [massageCategory, setMassageCategory] = useState([])
    const [foods, setFood] = useState([])
    const {state} = useLocation()
    const {t} = useTranslation()

    const convertDate = (inputDate) => {
       if (!inputDate) return
       const date = new Date(inputDate);
       const days = t('weekDays').split(',');
       const dayOfWeek = days[date.getDay()];
       const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
       const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
       const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
       const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
       const time = state?.notification?.service !== 'Cleaning' ? `, ${hours}:${minutes}` : ''
       return `${day}.${month} ${dayOfWeek}${time}`;
    }

    useEffect(() => {
        (async () => {
            const id = state.notification.id
            await api(`/telegram/notifications-text/${state.notification.text_template}/`).then(({data: {id, ...data}}) => setData(state => ({...state, ...data})))
            switch (state?.notification?.service) {
                case 'DryCleaning':
                    api(`/cleaning/dry-cleaning/${id}/`).then(({data}) => setData(state => ({...state, ...data, headerTitle: t('dryAndWashing')})))
                    break
                case 'Cleaning':
                    api(`/cleaning/cleaning/${id}/`).then(({data}) => setData(state => ({...state, ...data, headerTitle: t('cleaningRoom')})))
                    break
                case 'Order':
                    api(`/kitchen/order/${id}/`).then(({data}) => setData(state => ({...state, ...data, headerTitle: t('restaurantOrder')})))
                    break
                case 'Spa':
                    api(`/spa/${id}/`).then(({data}) => setData(state => ({...state, ...data, headerTitle: t('sauna')})))
                    break
                case 'Massage':
                    api(`/massage/massage/${id}/`).then(({data}) => setData(state => ({...state, ...data, headerTitle: t('massage')})))
                    break
            }
        })()
    }, [])

    const paymentMethods = useMemo(() => {
        return {
            'cash': t('cash'),
            'payment by card': t('payByCard'),
            'to number': t('writeToNumber'),
        }
    }, [])

    useEffect(() => {
        api('cleaning/cleaning-category').then(({data}) => setCleaningCategory(data))
        api('/spa/category/').then(({data}) => setSpaCategory(data))
        api('/kitchen/food/').then(({data}) => setFood(data))
        api('/massage/massage-category/').then(({data}) => setMassageCategory(data))
    }, [])

    return (
        <div>
            <DynamicHeader>{data?.headerTitle}</DynamicHeader>
            <TitleHeader>
                <Icon id={state.notification.status === 'Accepted' ? 'success' : 'error'} width='24px' height='24px'/>
                {data?.title}
            </TitleHeader>
            <p style={{marginBottom: 22}}>{data?.text}</p>
            {state?.notification?.service.match(/Spa|Massage/) && <p style={{color: '#7E7E7E', fontSize: 14, fontStyle: 'italic', marginBottom: 22}}>{t('hotelNotification')}</p>}
            <Receipt>
                <Flex direction='column' gap='12px'>
                    <GrayText>{getDate(state.notification.date)}</GrayText>
                    {state?.notification?.service === 'Cleaning' && (
                      <>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('date')}</Title>
                              <GoldenText $fs='14px'>{convertDate(data?.day)}</GoldenText>
                          </Flex>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('time')}</Title>
                              <GoldenText $fs='14px'>{data?.date_time}</GoldenText>
                          </Flex>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('cleaningType')}</Title>
                              <GoldenText $fs='14px'>{cleaningCategory.filter(({id}) => data?.cleaning_category?.includes(id)).map(({title}) => title).join(', ')}</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <Total>{t('total')}: </Total>
                              <GoldenText fs='16px'>{t('free')}</GoldenText>
                          </Flex>
                      </>
                    )}
                    {state?.notification?.service === 'Spa' && (
                      <>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('dateAndTime')}</Title>
                              <GoldenText $fs='14px'>{convertDate(data?.date_time)}</GoldenText>
                          </Flex>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('procedure')}</Title>
                              <GoldenText $fs='14px'>{t('sauna')}</GoldenText>
                          </Flex>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('duration')}</Title>
                              <GoldenText $fs='14px'>{spaCategory.find(({id}) => id === data?.duration)?.title}</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <Total>{t('total')}: </Total>
                              <GoldenText fs='16px'>{t('free')}</GoldenText>
                          </Flex>
                      </>
                    )}
                    {state?.notification?.service === 'Order' && (
                      <>
                          <Foods>
                              {data?.orderfoods?.map(({food,amount, food_price_ammount, id}) => (<OrderedFood key={id}>
                                  <OrderedFoodImg src={foods.find(({id}) => id === food)?.image}/>
                                  <Title fw={400} fs={14}>{foods.find(({id}) => id === food)?.name}</Title>
                                  <Compound>{amount} x <GoldenText>{food_price_ammount} c</GoldenText></Compound>
                              </OrderedFood>))}
                          </Foods>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <GrayText>{t('service')} {percent}%</GrayText>
                              <GoldenText>{data?.total_price ? Math.round(data?.total_price * (percent / (100 + percent))) : ''} с</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <GrayText>{t('paymentMethod')}</GrayText>
                              <GoldenText>{paymentMethods[data?.payment_type]}</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <GrayText>{t('quantity')}: {data?.orderfoods?.length}</GrayText>
                              <div>
                                  <Total>{t('total')}: </Total>
                                  <GoldenText fs='16px'>{data?.total_price} с</GoldenText>
                              </div>
                          </Flex>
                      </>
                    )}

                    {state?.notification?.service === 'Massage' && (
                      <>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('dateAndTime')}</Title>
                              <GoldenText $fs='14px'>{convertDate(data?.date_time)}</GoldenText>
                          </Flex>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('massageType')}</Title>
                              <GoldenText $fs='14px'>{massageCategory.find(({id}) => id === data?.category)?.name}</GoldenText>
                          </Flex>
                          <Flex jc='space-between'>
                              <Title fs='14px'>{t('duration')}</Title>
                              <GoldenText $fs='14px'>{massageCategory.find(({id}) => id === data?.category)?.duration}</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <GrayText>{t('service')} {percent}%</GrayText>
                              <GoldenText $fs='14px'>{Math.round(massageCategory.find(({id}) => id === data?.category)?.price * (percent / 100))} с</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <GrayText>{t('paymentMethod')}</GrayText>
                              <GoldenText $fs='14px'>{paymentMethods[data?.payment_type]}</GoldenText>
                          </Flex>
                          <DividerLineGray/>
                          <Flex jc='space-between'>
                              <Total>{t('total')}: </Total>
                              <GoldenText fs='16px'>{Math.round(massageCategory.find(({id}) => id === data?.category)?.price * (1 + percent / 100))} с</GoldenText>
                          </Flex>
                      </>
                    )}
                </Flex>
            </Receipt>
        </div>
    );
};

export default NotificationDetail;

const TitleHeader = styled.div`
  display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 14px;
`

const Receipt = styled.div`
  border-radius: 16px;
  border: 1px solid var(--grey, #F4F5F7);
  background: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin-top: auto;
`

const DividerLineGray = styled.span`
  width: 100%;
  height: 1px;
  background: var(--background-color);
`

const Total = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

const GoldenText = styled.span`
  font-size: ${({$fs}) => $fs || '16px'};
  font-weight: 700;
  color: #D07B4B;
`

const GrayText = styled.span`
  font-size: 14px;
  color: var(--text-light);
`

const Foods = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderedFood = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 8px 0 0;
  gap: 10px;
`

const Compound = styled.div`
  color: var(--text-light);
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 14px;
  margin-left: auto;
    display: flex;
    align-items: center;
    gap: 4px;
`

const OrderedFoodImg = styled.img`
  max-width: 40px;
  max-height: 40px;
`
