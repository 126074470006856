import styled from "styled-components";
import {FlexScroll} from "../UI/globalStyles";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchSliderInfo} from "../store/main/mainAction";

const Places = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const slider = useSelector(state => state.mainSlice.sliderData)
    useEffect(() => {
        dispatch(fetchSliderInfo())
    }, [])

    return (
        <FlexScroll>
            {
                slider?.map(item => (
                    <Card onClick={() => navigate(`venue/${item.id}`)} img={item.icon_url} key={item.id}>
                        <Title>
                            <PlaceIcon>
                                <IconImg src={item.little_icon_url}/>
                            </PlaceIcon>
                            {item.title}
                        </Title>
                    </Card>
                ))
            }
        </FlexScroll>
    );
};

export default Places;

const Card = styled.div`
  position: relative;
  width: 280px;
  min-height: 160px;
  border-radius: 20px;
  background: ${({img}) => `url(${img})`};
  background-size: cover;
  flex-shrink: 0;
  cursor: pointer;
`

const Title = styled.div`
  position: absolute;
  display: flex;
  column-gap: 8px;
  align-items: center;
  bottom: 16px;
  left: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
`

const PlaceIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%);
  width: 32px;
  height: 32px;
`

const IconImg = styled.img`
    width: 16px;
    height: 16px;
`