import DynamicHeader from "../components/DynamicHeader";
import {Flex, Title} from "../UI/globalStyles";
import styled from "styled-components";
import UiButton from "../UI/UiButton";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchDryCleaningInfo, transportToLaundry} from "../store/service/serviceAction";
import {useNavigate} from "react-router-dom";
import {toMain} from "../store/service/serviceSlice";
import {Loader} from "../components";

const DryCleaning = () => {
   const {t} = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const success = useSelector(state => state.serviceSlice.success)
   const info = useSelector(state => state.serviceSlice.dryCleaningInfo)
   const loading = useSelector(state => state.serviceSlice.loading)
   const navigateToMain = () => {
      dispatch(toMain())
      navigate('/')
   }
   useEffect(() => {
      dispatch(fetchDryCleaningInfo())
      return () => {
         dispatch(toMain())
      }
   },[])

   if (!info?.length || loading) {
      return <Loader/>
   }
   return (
      <Content>
         <DynamicHeader>{t('dryAndWashing')}</DynamicHeader>
         <Flex direction='column' fg='1'>
            {
               success ? (
                  <>
                     <Title mt='auto' ta='center'>{t('successOrderDescription')}</Title>
                     <UiButton handleClick={navigateToMain} style={{marginTop: 'auto', marginBottom: '20px'}}>
                        <Title ta='center' fs='16px' fw='600'>
                           {t('toMain')}
                        </Title>
                     </UiButton>
                  </>
               ) : (
                  <>
                     {
                        info?.map(item => (
                           <DefaultText key={item.id} dangerouslySetInnerHTML={{ __html: item.text }}/>
                        ))
                     }
                     <UiButton handleClick={() => dispatch(transportToLaundry(new Date().toISOString()))} style={{marginTop: 'auto', marginBottom: '20px'}}>
                        <Title ta='center' fs='16px' fw='600'>
                           {t('askTakeThings')}
                        </Title>
                     </UiButton>
                  </>
               )
            }
         </Flex>
      </Content>
   );
};

export default DryCleaning;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`

const DefaultText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 20px;
`