import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {Flex, Heading, Title} from "../UI/globalStyles";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {cleanTheDishes} from "../store/kitchen/kitchenAction";

const LinkA = styled(Link)`
  text-decoration: none;
  color: var(--text-color)
`

const Services = () => {
  const [showDishesBlock, setShowDishesBlock] = useState(false);
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const services = useMemo(() => {
    return [
      {
        icon: 'food',
        title: t('restaurant'),
        id: '1',
        link: '/restaurant'
      },
      {
        icon: 'sauna',
        title: t('sauna'),
        id: '2',
        link: '/sauna'
      },
      {
        icon: 'massage',
        title: t('massage'),
        id: '3',
        link: '/massage'
      },
      {
        icon: 'cleaning',
        title: t('cleaningRoom'),
        id: '4',
        link: '/cleaning'
      },
      {
        icon: 'dry',
        title: t('dry'),
        id: '5',
        link: '/drycleaning'
      },
    ]
  }, [])

  useEffect(() => {
    const takeOffDishTime = localStorage.getItem('takeOffDishTime')
    if (takeOffDishTime && Number(takeOffDishTime) <= Date.now()) {
      setShowDishesBlock(true);
    }
  }, []);

  const handleHide = () => {
    localStorage.setItem('takeOffDishTime', (Date.now() + (30 * 60 * 1000)).toString())
    setShowDishesBlock(false);
  };
  const handleRemove = () => {
    localStorage.removeItem("takeOffDishTime")
    setShowDishesBlock(false);
  }

  const handleYes = () => {
    dispatch(cleanTheDishes())
    localStorage.removeItem("takeOffDishTime")
    setShowDishesBlock(false);
  }

  return (
    <Margin>
      <Heading>{t('orderService')}</Heading>
      <div>
        {
          showDishesBlock && (
            <DishBlock>
              <Title fw='700'>Убрать посуду?</Title>
              <Flex gap='10px' jc='space-between'>
                <Button $primary onClick={handleYes}>{t('yes')}</Button>
                <Button onClick={handleRemove}>{t('no')}</Button>
                <Button onClick={handleHide}>{t('later')}</Button>
              </Flex>
            </DishBlock>
          )
        }
        <ServicesBlock>
          {
            services.map(item => (
                <LinkA key={item.id} to={item.link}>
                  <Service>
                    <Icon fill='#000' id={item.icon} height='24px' width='24px'/>
                    <ServiceName>{item.title}</ServiceName>
                  </Service>
                </LinkA>
              )
            )
          }
        </ServicesBlock>
      </div>
    </Margin>
  );
};

export default Services;

const Margin = styled.div`
  margin-top: 24px;
`

const ServicesBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 11px;
  margin-top: 20px;
`

const Service = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;
  background: #F4F5F7;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
`

const ServiceName = styled.h3`
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
`

const DishBlock = styled.div`
  padding: 20px;
  background: #F4F5F7;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  gap: 10px;
  margin-top: 10px;
`

const Button = styled.button`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: transparent;
  border-radius: 16px;
  font-weight: 600;
  border: 2px solid var(--button, #EDA075);
  color: #D07B4B;
  cursor: pointer;
  height: 46px;
  font-size: 16px;
  ${({$primary}) => $primary && `
  border: transparent;
  background: var(--button, linear-gradient(222deg, #EDA075 0%, #D07B4B 95.79%));
  color: #fff;
`}
`