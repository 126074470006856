import Icon from "../assets/Icons/Icon";
import styled from "styled-components";
import {useRef, useState} from "react";
import {useOutsideClick} from "../hooks";
import {useTranslation} from "react-i18next";

const langs = [
    {
        id: 'ru',
        title: 'Русский'
    },
    {
        id: 'en',
        title: 'English'
    },
    {
        id: 'ko',
        title: '한국인'
    },
    {
        id: 'zh',
        title: '中國人'
    },
    {
        id: 'tr',
        title: 'Türkçe'
    },
    {
        id: 'ar',
        title: 'عرب'
    }
]

const Language = () => {
    const ref = useRef()
    const [isExitVisible, setIsExitVisible] = useState(false);
    const handleOutsideClick = () => {
        setIsExitVisible(false)
    }
    useOutsideClick(ref, handleOutsideClick)
    const {i18n} = useTranslation()

    const onLanguageClick = async (el) => {
        await i18n.changeLanguage(el.target.dataset.language)
        window.location.reload()
    }

    return (
        <Lang>
            <Icon id='lang' width='24px' height='24px' onClick={() => setIsExitVisible(!isExitVisible)}/>
            <LangBlock ref={ref} $isVisible={isExitVisible} >
                {
                    langs.map(item => (
                        <List data-language={item.id} key={item.id} onClick={onLanguageClick}><Icon id={item.id} width='24px' height='24px'/>{item.title}</List>
                    ))
                }
            </LangBlock>
        </Lang>
    );
};

export default Language;

const Lang = styled.div`
  position: relative;
  margin-left: auto;
`

const LangBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  padding: 12px 15px;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  border-radius: 16px;
  background: #fff;
  border: 1px solid var(--grey, #F4F5F7);
  position: absolute;
  top: 30px;
  right: calc(100% - 24px);
  visibility: ${({$isVisible}) => ($isVisible ? 'visible' : 'hidden')};
`

const List = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`