import DynamicHeader from "../components/DynamicHeader";
import {DatePicker, Loader, PaymentMethods} from "../components";
import React, {useEffect, useMemo, useState} from "react";
import {Flex, FlexScroll, Title} from "../UI/globalStyles";
import styled from "styled-components";
import UiButton from "../UI/UiButton";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {fetchMassageCategories, orderMassage} from "../store/service/serviceAction";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {toMain} from "../store/service/serviceSlice";
import {getDate} from "../utils";

const Massage = () => {
   const dispatch = useDispatch()
   const categories = useSelector(state => state.serviceSlice.massageCategories)
   const success = useSelector(state => state.serviceSlice.success)
   const loading = useSelector(state => state.serviceSlice.loading)
   const [selectedDate, setSelectedDate] = useState({date: '', time: '', isoDate: ''})
   const [selectedType, setSelectedType] = useState(null)
   const [cashSum, setCashSum] = useState('')
   const [error, setError] = useState('')
   const [selectedPayment, setSelectedPayment] = useState('cash');
   const navigate = useNavigate()
   const {t} = useTranslation()
   const price = selectedType?.price && selectedType?.price + ' c'

   const paymentMethods = useMemo(() => {
      return [{
         title: t('cash'), id: 'cash'
      }, {
         title: t('payByCard'), id: 'payment by card'
      }, {
         title: t('writeToNumber'), id: 'to number'
      }]
   }, [])

   const selectedIndex = paymentMethods.findIndex((method) => method.id === selectedPayment);

   useEffect(() => {
      dispatch(fetchMassageCategories())
      return () => {
         dispatch(toMain())
      }
   }, [])

   const handleOrder = () => {
      if(!selectedDate.date) {
        return setError(t('chooseDate'))
      }
      if (!selectedType) {
         return toast.error(t('chooseMassage'), {duration: 2000})
      }

      const date_time = new Date(selectedDate.rawDate).toISOString()
      const data = {
         date_time,
         category: selectedType?.id,
         payment_type: selectedPayment,
         change_with: selectedPayment === 'cash' ? cashSum : ''
      }
      dispatch(orderMassage(data))
   }

   const navigateToMain = () => {
      dispatch(toMain())
      navigate('/')
   }

   useEffect(() => {
      setError('')
   }, [selectedDate, selectedType])

   if (!categories || loading) {
      return <Loader/>
   }

   return (
      <Content>
         <DynamicHeader>{t('massage')}</DynamicHeader>
         <Flex direction='column' fg='1'>
            {
               success ? (
                  <>
                     <Receipt>
                        <Title ta='center'>{t('successOrderDescription')}</Title>
                        <DividerLineGray/>
                        <Flex direction='column' gap='12px'>
                           <GrayText>{getDate()}</GrayText>
                           <Flex jc='space-between'>
                              <Title fs='14px'>{t('dateAndTime')}</Title>
                              <GoldenText $fs='14px'>{`${selectedDate.date}, ${selectedDate.time}`}</GoldenText>
                           </Flex>
                           <Flex jc='space-between'>
                              <Title fs='14px'>{t('massageType')}</Title>
                              <GoldenText $fs='14px'>{selectedType?.name}</GoldenText>
                           </Flex>
                           <Flex jc='space-between'>
                              <Title fs='14px'>{t('duration')}</Title>
                              <GoldenText $fs='14px'>{selectedType?.duration}</GoldenText>
                           </Flex>
                           <DividerLineGray/>
                           <Flex jc='space-between'>
                              <GrayText>{t('service')}</GrayText>
                           </Flex>
                           <DividerLineGray/>
                           <Flex jc='space-between'>
                              <GrayText>{t('paymentMethod')}</GrayText>
                              <GoldenText $fs='14px'>{paymentMethods[selectedIndex].title}</GoldenText>
                           </Flex>
                           <DividerLineGray/>
                           <Flex jc='space-between'>
                              <Total>{t('total')}: </Total>
                              <GoldenText fs='16px'>{price}</GoldenText>
                           </Flex>
                        </Flex>
                     </Receipt>
                     <div style={{margin: 'auto 0 20px'}}>
                        <UiButton handleClick={navigateToMain}>
                           <Title ta='center' fs='16px'>{t('toMain')}</Title>
                        </UiButton>
                     </div>
                  </>
               ) : (
                  <>
                     <Title fs='14px' mb='5px' mt='20px'>{t('dateAndTime')}</Title>
                     <ContainerRelative>
                        <DatePicker addedTime={90} selectedDate={selectedDate} setSelectedDate={setSelectedDate} error={error}/>
                        <Label>{error}</Label>
                     </ContainerRelative>
                     <Title fs='14px' mb='5px'>{t('massageType')}</Title>
                     <FlexScroll>
                        {
                           categories.map(item => (
                              <TypeMassage key={item.id} onClick={() => setSelectedType(item)}>
                                 <RadioBtn>
                                    <StyledRadio className={selectedType?.id === item.id ? 'active' : ''}/>
                                    <MassageName>{item.name}</MassageName>
                                 </RadioBtn>
                                 <DividerLine/>
                                 <GrayText>{t('duration')}: {item.duration}</GrayText>
                                 <Title fs='14px'>{t('price')}: <Price>{item.price} с</Price></Title>
                              </TypeMassage>
                           ))
                        }
                     </FlexScroll>
                     <div style={{marginTop: '30px'}}>
                        <PaymentMethods selectedPayment={selectedPayment} cashSum={cashSum} setCashSum={setCashSum}
                                        setSelectedPayment={setSelectedPayment}/>
                     </div>
                     <Flex direction='column' gap='20px' mt='auto' mb='20px'>
                        <PriceBlock>
                           <GrayTitle>{t('cost')}</GrayTitle>
                           <GoldenText>{price}</GoldenText>
                        </PriceBlock>
                        <UiButton handleClick={handleOrder}>
                           <Flex gap='10px' jc='center'>
                              <Title fs='16px'>{t('placeAnOrder')}</Title>
                              <Title fw='700'>{price}</Title>
                           </Flex>
                        </UiButton>
                     </Flex>
                  </>
               )
            }
         </Flex>
      </Content>
   );
};

export default Massage;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`

const TypeMassage = styled.div`
  border-radius: 20px;
  background: var(--background-color);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  cursor: pointer;
`

const RadioBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: transparent;
`

const StyledRadio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background: var(--primary-color);
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #F4F5F7;
    }
  }
`

const GrayText = styled.span`
  font-size: 14px;
  color: var(--text-light);
`

const Price = styled.span`
  color: #D07B4B;
  font-size: 14px;
`

const DividerLine = styled.span`
  width: 100%;
  height: 1px;
  background: #fff;
`

const PriceBlock = styled.div`
  border-radius: 16px;
  background: var(--background-color);
  padding: 0 20px;
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const GoldenText = styled.p`
  font-size: ${({$fs}) => $fs || '16px'};
  font-weight: 700;
  color: #D07B4B;
`

const GrayTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-light);
`

const Receipt = styled.div`
  border-radius: 16px;
  border: 1px solid var(--grey, #F4F5F7);
  background: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin-top: auto;
`


const DividerLineGray = styled.span`
  width: 100%;
  height: 1px;
  background: var(--background-color);
`


const Total = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

const MassageName = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: #000;
`


const Label = styled.span`
  font-size: 13px;
  color: #fa0808;
  position: absolute;
  bottom: -2px;
  left: 5px;
`

const ContainerRelative = styled.div`
  position: relative;
  padding-bottom: 15px;
`
