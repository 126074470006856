import {Flex, Title} from "../UI/globalStyles";
import Qr from "../assets/images/qr.svg"
import DynamicHeader from "../components/DynamicHeader";
import styled from "styled-components";
import UiButton from "../UI/UiButton";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const PhoneBook = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()

    return (
        <Content>
          <DynamicHeader></DynamicHeader>
            <Flex gap='40px' direction='column' ai='center' mt='auto' jc='space-between'>
                <Description>{t('scanDescription')}</Description>
                <QrImage src={Qr} alt=""/>
            </Flex>
            <Buttons>
                <Button onClick={() => navigate(-1)}>{t('scanLater')}</Button>
               <UiButton onClick={() => navigate('/qrScan', {state: {phonebook: true}, replace: true})}><Title ta='center' fw='600'>{t('scan')}</Title></UiButton>
            </Buttons>
        </Content>
    );
};

export default PhoneBook;


const QrImage = styled.img`
  max-width: 232px;
  max-height: 238px;
`

const Description = styled.p`
  text-align: center;
`

const Button = styled.button`
  background: none;
  border: transparent;
  color: #D07B4B;
  padding: 16px 24px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
`

const Content = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Buttons = styled.div`
  margin-bottom: 20px;
  margin-top: auto;
`