import axios from 'axios';
import toast from "react-hot-toast";
import i18n from "i18next";

const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL || 'https://vp.ambassador.kg/api/v1/',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};


api.interceptors.response.use(
  (response) => response,
  async (error) => {
     console.log(error)
    const orgRequest = error.config

    if (error.response.status === 401) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject});
        })
          .then(token => {
            orgRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(orgRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken')
        const {
          access,
        } = (await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL || 'https://vp.ambassador.kg/api/v1/'}accounts/token/refresh/`, {refresh: refreshToken})).data
        localStorage.setItem('accessToken', access)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access;
        orgRequest.headers['Authorization'] = 'Bearer ' + access;
        processQueue(null, access);
        return axios(orgRequest)
      } catch (e) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('date_in')
        localStorage.removeItem('date_out')
        localStorage.removeItem('username')
        window.location.reload()
      } finally {
        isRefreshing = false
      }
    }

    if (error.response.status !== 401) {
      toast.error(error.message, {duration: 3000,})
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    config.headers['Accept-Language'] = i18n.language === 'zh' ? 'zh-TW' : i18n.language;
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }
);


export default api;