import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {Flex} from "../UI/globalStyles";
import DynamicHeader from "../components/DynamicHeader";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchDetailedNotification} from "../store/main/mainAction";
import {useLocation, useParams} from "react-router-dom";
import {Loader} from "../components";

const Receipt = () => {
   const {t} = useTranslation()
   const dispatch = useDispatch()
   const {state} = useLocation()
   const notification = useSelector(state => state.mainSlice.notificationDetails)
   // const convertDate = (inputDate) => {
   //    const date = new Date(inputDate);
   //    const days = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];
   //    const dayOfWeek = days[date.getDay()];
   //    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
   //    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
   //    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
   //    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
   //    return `${day}.${month} ${dayOfWeek}, ${hours}:${minutes}`;
   // }
   useEffect(() => {
      dispatch(fetchDetailedNotification({model: state.notification.order_object._state, id: state.notification.order_object.id}))
   }, [])

   if(!notification) {
      return <Loader/>
   }
    return (
        <div>
            <DynamicHeader>{t('restaurantOrder')}</DynamicHeader>
            <div style={{marginTop: '20px'}}>
                <Title><Icon id='success' width='24px' height='24px'/>{t('yourOrderAccepted')}</Title>
                <p>{t('orderDeliveryDescription')}</p>
            </div>
            <Note>
                <GrayText>21.09.2023 21:30</GrayText>
                <Flex direction='column' gap='10px'>
                    <Type>Дата и время <Designated>26.08 сб, 9:40</Designated></Type>
                    <Type>Дата и время <Designated>Классический</Designated></Type>
                    <Type>Дата и время <Designated>1 час</Designated></Type>
                    <Payment>
                        <GrayText>Способ оплаты</GrayText>
                        <Designated>Оплата картой</Designated>
                    </Payment>
                    <Price>
                        <GrayText>Стоимость оплаты</GrayText>
                        <Designated>1200</Designated>
                    </Price>
                </Flex>
            </Note>
        </div>
    );
};

export default Receipt;

const Title = styled.h2`
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
`

const Note = styled.div`
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  width: 100%;
  margin-top: 20px;
  border: 1px solid #F4F5F7;
`

const GrayText = styled.span`
  font-size: 14px;
  color: var(--text-light)
`

const Type = styled.div`
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 12px;
  }
`

const Payment = styled.div`
  border-top: 1px solid #F4F5F7;
  border-bottom: 1px solid #F4F5F7;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`

const Price = styled.div`
  display: flex;
  justify-content: space-between;
`

const Designated = styled.span`
  font-weight: 600;
  color: #D07B4B;
`