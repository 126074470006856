import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import UiButton from "../UI/UiButton";
import {Link} from "react-router-dom";
import styled from "styled-components";
import DynamicHeader from "./DynamicHeader";
import {Flex, Title} from "../UI/globalStyles";
import Qr from "../assets/images/qr.svg";

export const PrivateRoute = ({Component}) => {
    const isAuth = useSelector(state => state.mainSlice.isAuth)
    const {t} = useTranslation()

    if (isAuth) return <Component/>

    return (
        <Root style={{margin: 'auto'}}>
           <DynamicHeader>{t('signToAccount')}</DynamicHeader>
           <Flex direction='column' ai='center' gap='40px'>
              <h3 style={{fontSize: 20, fontWeight: '600', marginBottom: 30, textAlign: 'center'}}>{t('privateRouteTitle')}</h3>
              <QrImage src={Qr} alt=""/>
              <DescriptionText>{t('authDescription')}</DescriptionText>
           </Flex>
           <Flex direction='column' width='100%' mb='20px'>
              <ScanButton to='/'>
                 <Button>
                    <Title fs='16px' fw='400' ta='center'>
                       {t('scanLater')}
                    </Title>
                 </Button>
              </ScanButton>
              <ScanButton to='/qrScan' state={{auth: true}}>
                 <UiButton>
                    <Title fw='600' ta='center'>
                       {t('scan')}
                    </Title>
                 </UiButton>
              </ScanButton>
           </Flex>
        </Root>
    );
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  min-height: 100dvh;
`

const ScanButton = styled(Link)`
  width: 100%;
  font-size: 16px;
`

const QrImage = styled.img`
  max-width: 232px;
  max-height: 238px;
`

const DescriptionText = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 150%;
`

const Button = styled.button`
  background: none;
  border: transparent;
  color: #D07B4B;
  width: 100%;
  cursor: pointer;
  padding: 16px 24px;
`