import {Flex, Title} from "../UI/globalStyles";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {useTranslation} from "react-i18next";
import {useOutsideClick} from "../hooks";
import {Timepicker} from "./Timepicker";
import {useLocation} from "react-router-dom";


const useDatesInRange = (startDate, endDate) => {
   const [dates, setDates] = useState([]);
   const {t} = useTranslation();

   useEffect(() => {
      let date = new Date(startDate);
      const today = new Date()

      if (date < today) {
         date = today
      }

      const weekDays = t('weekDays').split(',');
      const dates = [];

      while (date <= new Date(endDate)) {
         const day = date.getDate();
         const month = date.getMonth() + 1;
         const weekDay = date.getDay();
         const year = date.getFullYear()
         dates.push({
            day: day < 10 ? `0${day}` : day,
            month: month < 10 ? `0${month}` : month,
            weekDay: weekDay === 0 ? weekDays[6] : weekDays[weekDay - 1],
            year,
            rawDate: new Date(date)
         });
         date.setDate(date.getDate() + 1);
         date.setHours(0)
         date.setMinutes(0)
         date.setSeconds(0)
      }
      setDates(dates);
   }, [startDate, endDate]);

   return dates;
};

const times = ['9:00-12:00', '12:00-15:00', '15:00-18:00']

const DatePicker = ({selectTime, selectedDate, setSelectedDate, error, addedTime = 0}) => {
   const startDate = useSelector(state => state.mainSlice.date_in)
   const endDate = useSelector(state => state.mainSlice.date_out)
   const [page, setPage] = useState(1);
   const [showDatePicker, setShowDatePicker] = useState(false)
   const [activeTime, setActiveTime] = useState({hour: 0, minute: 0})
   const dates = useDatesInRange(startDate, endDate);
   const ref = useRef()
   const {t} = useTranslation()
   const location = useLocation();
   useOutsideClick(ref, () => setShowDatePicker(false))

   const datesInView = useMemo(() => {
      return dates.slice((page - 1) * 7, (page - 1) * 7 + 7);
   }, [page, dates]);

   useEffect(() => {
      if (location.pathname.includes('/cleaning')) {
         return setSelectedDate({...selectedDate, time: '9:00-12:00'})
      }
      const time = `${activeTime.hour < 10 ? '0' + activeTime.hour : activeTime.hour}:${activeTime.minute < 10 ? '0' + activeTime.minute : activeTime.minute}`
      setSelectedDate({...selectedDate, time})
   }, [activeTime])

   return (
      <RelativeContainer ref={ref}>
         <DateInput $error={error} onClick={() => setShowDatePicker(!showDatePicker)}>
            <Icon id='datepicker'/>
            <DateInputText
               $date={selectedDate.date}>{selectedDate.date ? `${selectedDate.date}, ${selectedDate.time}` : t('selectDateAndTime')}</DateInputText>
            <ArrowBtn $open={showDatePicker} id='arrow' width='16px' height='16px'/>
         </DateInput>
         {
            showDatePicker && (
               <CalendarContainer>
                  <CalendarHeader>
                     <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
                        <ArrowIcon id='arrow' width='16px' height='16px'/>
                     </Button>
                     <Title>{t('dateAndTime')}</Title>
                     <Button disabled={Math.ceil(dates.length / 7) === page} onClick={() => setPage(page + 1)}>
                        <ArrowIcon id='arrow' $next width='16px' height='16px'/>
                     </Button>
                  </CalendarHeader>
                  <DividerLine/>
                  <DateWrapper>
                     {datesInView.map(({day, weekDay, month, year, rawDate}) => {
                        return (
                           <DateItem key={day}
                                     onClick={() => {
                                        setSelectedDate({
                                           ...selectedDate,
                                           date: `${day}.${month} ${weekDay}`,
                                           isoDate: `${year}-${month}-${day}`,
                                           rawDate
                                        })
                                     }}
                                     $selected={selectedDate.date === `${day}.${month} ${weekDay}`}>
                              <Title>{weekDay}</Title>
                              <GrayText $selected={selectedDate.date === `${day}.${month} ${weekDay}`}>
                                 {`${day}.${month}`}
                              </GrayText>
                           </DateItem>
                        );
                     })}
                  </DateWrapper>

                  <DividerLine/>
                  {
                     !selectTime ? (
                        <Timepicker selectedDate={selectedDate} time={activeTime} setTime={setActiveTime} addedTime={addedTime}/>
                     ) : (
                        <Flex direction='column' gap='10px'>
                           {times.map((item) => (
                              <RadioBtn onClick={() => setSelectedDate({...selectedDate, time: item})} key={item}>
                                 <StyledRadio className={selectedDate.time === item ? 'active' : ''}/>
                                 <TimeSpan>{item}</TimeSpan>
                              </RadioBtn>
                           ))}
                        </Flex>
                     )
                  }

                  <DividerLine/>
                  <GoldenBtn onClick={() => setShowDatePicker(false)}>{t('ready')}</GoldenBtn>
               </CalendarContainer>
            )
         }
      </RelativeContainer>
   );
};

export default DatePicker;

const RelativeContainer = styled.div`
  position: relative;
`

const CalendarContainer = styled.div`
  position: absolute;
  z-index: 10 ;
  width: 100%;
  margin: 5px 0 20px;
  border-radius: 12px;
  border: 1px solid #E7E6E6;
  background: #fff;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  user-select: none;
`;

const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  background: ${props => (props.disabled ? 'gray' : 'transparent')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  background: none;
  border: transparent;
  min-width: 16px;
  min-height: 16px;
`;

const DateWrapper = styled.div`
  display: grid;
   grid-template-columns: repeat(7, auto);
   justify-items: center;
`;

const DateItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${({$selected}) => $selected ? 'var(--secondary-text-color)' : '#000'};
  cursor: pointer;
  border-radius: 12px;
  max-width: 48px;
  width: 100%;
  height: 48px;
  position: relative;

  span {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: ${({$selected}) => $selected ? 'var(--primary-color)' : 'none'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ArrowIcon = styled(Icon)`
  transform: ${({$next}) => $next ? 'rotate(-90deg)' : 'rotate(90deg)'};
`

const GrayText = styled.span`
  font-size: 12px;
  color: ${({$selected}) => $selected ? 'var(--secondary-text-color)' : 'var(--text-light)'};
`

const DividerLine = styled.span`
  width: 100%;
  height: 1px;
  background: #F4F5F7;
`

const GoldenBtn = styled.button`
  background: none;
  border: transparent;
  cursor: pointer;
  align-self: flex-end;
  color: #D07B4B;
  font-size: 16px;
  font-weight: 500;
`

const DateInput = styled.div`
  border-radius: 10px;
  background: #F4F5F7;
  padding: 0 15px;
  cursor: pointer;
  color: #C0C0CF;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 14px;
  user-select: none;
  height: 44px;
  border: ${({$error}) => $error ? '1px solid #fd0505' : 'none'};
`

const DateInputText = styled.span`
  font-size: 14px;
  color: ${({$date}) => $date ? '#000' : '#C0C0CF'};
`

const ArrowBtn = styled(Icon)`
  margin-left: auto;
  transform: ${({$open}) => $open ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const RadioBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  background: none;
  border: transparent; 
  cursor: pointer;
  width: 120px;
`

const StyledRadio = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #F4F5F7;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &.active {
    background: var(--primary-color);
    position: relative;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #F4F5F7;
    }
  }
`

const TimeSpan = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 400;
`