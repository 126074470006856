import {createSlice} from '@reduxjs/toolkit';
import {
   callCleaning,
   fetchDryCleaningInfo,
   fetchMassageCategories,
   orderMassage,
   orderSpa,
   transportToLaundry
} from "./serviceAction";


const serviceSlice = createSlice({
   name: 'serviceSlice',
   initialState: {
      massageCategories: null,
      dryCleaningInfo: [],
      success: false,
      loading: false,
   },
   reducers: {
      toMain(state) {
         state.success = false
      }
   },
   extraReducers: {
      [fetchMassageCategories.fulfilled]: (state, {payload}) => {
         state.massageCategories = payload
      },
      [fetchMassageCategories.pending]: (state, {payload}) => {
         state.massageCategories = payload
      },
      [fetchDryCleaningInfo.fulfilled]: (state, {payload}) => {
         state.dryCleaningInfo = payload
      },
      [orderMassage.fulfilled]: (state) => {
         state.success = true
         state.loading = false
      },
      [orderMassage.pending]: (state) => {
         state.loading = true
      },
      [orderSpa.fulfilled]: (state) => {
         state.success = true
         state.loading = false
      },
      [orderSpa.pending]: (state) => {
         state.loading = true
      },
      [transportToLaundry.fulfilled]: (state) => {
         state.success = true
         state.loading = false
      },
      [transportToLaundry.pending]: (state) => {
         state.loading = true
      },
      [callCleaning.pending]: (state) => {
         state.loading = true
      },
      [callCleaning.fulfilled]: (state) => {
         state.success = true
         state.loading = false
      },
   }
});

export const {
   toMain
} = serviceSlice.actions

export default serviceSlice.reducer;