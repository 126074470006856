import styled from "styled-components";
import {useRef} from "react";
import {useOutsideClick} from "../hooks";

export const ZoomedImage = ({active, setActive, children}) => {
   const ref = useRef()
   useOutsideClick(ref, () => setActive(false))
   return (
      <ModalContainer className={active ? 'active' : ''}>
         <InnerContainer>
            <ModalContent ref={ref} className={active ? 'active' : ''}>
               {children}
            </ModalContent>
         </InnerContainer>
      </ModalContainer>
   );
};


const ModalContainer = styled.div`
  width: 100vw;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .2s;
  pointer-events: none;
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
`

const ModalContent = styled.div`
  position: relative;
  transition: .2s;
  transform: scale(.5);
  &.active {
    transform: scale(1);
  }
`

const InnerContainer = styled.div`
  max-width: 600px;
`