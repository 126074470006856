import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Notification = ({service, order_object, status, actual_text, id, text_template}) => {
  const {t, i18n} = useTranslation()
  const getDate = (date) => {
    const inputDate = new window.Date(date);
    const options = {hour: '2-digit', minute: '2-digit', month: 'long', day: 'numeric'};
    return inputDate.toLocaleString(i18n.language, options);
  }
  const orderFrom = (service) => {
    switch (service) {
      case 'DryCleaning':
        return t('dryAndWashing');
      case 'Cleaning':
        return t('cleaning');
      case 'Order':
        return t('restaurantOrder');
      case 'Spa':
        return t('sauna');
      case 'Massage':
        return t('massage')
      default:
        return 'Результат по умолчанию';
    }
  }


  const checkStatus = (value) => {
    switch (value) {
      case 'Accepted':
        return 'success';
      case 'Decline' :
        return 'error';
      case null:
        return 'pending';
      default:
        return 'error'
    }
  }

  const defineText = (value) => {
    switch (value) {
      case 'Accepted':
        return t('yourOrderAccepted');
      case 'Decline' :
        return t('cantProcessOrder');
      case null:
        return t('orderIsPending');
      default:
        return t('cantProcessOrder');
    }
  }

  const handleClick = (e) => {
    if (!status) {
      e.preventDefault();
    }
  };


  return (
    <NotificationBlock to={`/notification/${id}`} state={{notification: {id: order_object?.id, text_template, service, status, date: order_object?.created_at}}} onClick={handleClick}>
      <Heading>
        <Title>{orderFrom(service)}</Title>
        <Date>{getDate(order_object?.created_at)}</Date>
      </Heading>
      <MessageBlock>
        <Icon id={checkStatus(status)} width='24px' height='24px'/>
        <Message>
          <span>{defineText(status)}</span>
          <p>{actual_text}</p>
        </Message>
      </MessageBlock>
    </NotificationBlock>
  );
};

export default Notification;

const NotificationBlock = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 15px;
  background: #fff;
  padding: 15px;
  border: 1px solid #F4F5F7;
  text-decoration: none;
  color: black;
`

const Title = styled.h4`
  font-size: 16px;
  font-weight: 600;
`

const Date = styled.span`
  font-size: 14px;
  color: var(--text-light);

`
const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
`

const MessageBlock = styled.div`
  display: grid;
  grid-template-columns: 30px 1fr;
  font-size: 14px;
  gap: 8px;
  align-items: center;
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`
