import {createSlice} from '@reduxjs/toolkit';
import {
  fetchActualDates, fetchDetailedNotification, fetchNotifications, fetchPercentForService,
  fetchRoomEquipmentInfo,
  fetchSliderInfo,
  fetchUsefulInfo,
  sendMessageToManagement,
  signIn
} from "./mainAction";

const checkAuth = () => {
  const keys = ['accessToken', 'refreshToken', 'date_in', 'date_out', 'username']
  return keys.map(key => localStorage.getItem(key)).every(data => !!data)
}

const mainSlice = createSlice({
  name: 'mainSlice',
  initialState: {
    username: localStorage.getItem('username'),
    isAuth: checkAuth(),
    date_in: localStorage.getItem('date_in'),
    date_out: localStorage.getItem('date_out'),
    sliderData: [],
    notifications: [],
    notificationDetails: null,
    usefulInfo: null,
    equipmentInfo: [],
    loading: false,
    percent: 0,
  },
  reducers: {},
  extraReducers: {
    [signIn.fulfilled]: (state, {payload}) => {
      if (new Date().getTime() > new Date(payload.date_out).getTime()) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('date_in')
        localStorage.removeItem('date_out')
        localStorage.removeItem('username')
        window.location.reload()
        return
      }
      localStorage.setItem('accessToken', payload.access)
      localStorage.setItem('refreshToken', payload.refresh)
      localStorage.setItem('date_in', payload.date_in)
      localStorage.setItem('date_out', payload.date_out)
      localStorage.setItem('username', payload.username)
      localStorage.setItem('hotel', payload.hotel)
      localStorage.setItem('roomNumber', payload.room_number)
      state.username = payload.username
      state.date_in = payload.date_in
      state.date_out = payload.date_out
      state.isAuth = true;
    },
    [fetchSliderInfo.fulfilled]: (state, {payload}) => {
      state.sliderData = payload
    },
    [fetchUsefulInfo.fulfilled]: (state, {payload}) => {
      state.usefulInfo = payload
    },
    [fetchRoomEquipmentInfo.fulfilled]: (state, {payload}) => {
      state.equipmentInfo = payload
    },
    [fetchActualDates.fulfilled]: (state, {payload}) => {
      const {date_in, date_out} = payload[0]

      if (new Date().getTime() > new Date(date_out).getTime()) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('date_in')
        localStorage.removeItem('date_out')
        localStorage.removeItem('username')
        window.location.reload()
        return
      }

      localStorage.setItem('date_in', date_in)
      localStorage.setItem('date_out', date_out)
      state.date_in = date_in
      state.date_out = date_out
    },
    [sendMessageToManagement.pending]: (state) => {
      state.loading = true
    },
    [sendMessageToManagement.fulfilled]: (state) => {
      state.loading = false
    },
    [fetchNotifications.fulfilled]: (state, {payload}) => {
      state.notifications = payload
    },
    [fetchDetailedNotification.fulfilled]: (state, {payload}) => {
      state.notificationDetails = payload
    },
    [fetchPercentForService.fulfilled]: (state, {payload}) => {
      state.percent = payload?.[0]?.percent
    }
  }
});

export const {
} = mainSlice.actions

export default mainSlice.reducer;
