import styled from "styled-components";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {
  Chat,
  Home,
  Management,
  Massage,
  Notifications,
  Sauna,
  Venue,
  Restaurant,
  Receipt,
  Cart,
  PhoneBook,
  Auth,
  QrScan,
  Cleaning,
  DryCleaning, FileView, NotificationDetail
} from "./pages";
import {Navbar, PrivateRoute} from "./components";
import Header from "./components/Header";
import {ScrollToTop} from "./components";
import {useDispatch, useSelector} from "react-redux";
import './i18n'
import {Toaster} from "react-hot-toast";
import {useEffect, useState} from "react";
import {fetchActualDates, fetchPercentForService} from "./store/main/mainAction";
import {onValue, ref, onChildAdded} from "firebase/database";
import {db} from "./firebase";

const App = () => {
  const isAuth = useSelector(state => state.mainSlice.isAuth)
  const {pathname} = useLocation();
  const dispatch = useDispatch()
  const [count, setCount] = useState(0)
  const routesWithoutNavbar = ["/venue", "/restaurant", "/cart", "/phonebook", "/auth", "/qrScan", "/sauna", "/massage", "/cleaning", "/drycleaning", '/file'];
  const routesWithHeader = ["/", "/notifications", "/chat", "/management"]

  const hideNavbar = routesWithoutNavbar.some((route) =>
    pathname.startsWith(route)
  );

  useEffect(() => {
    if (isAuth) {
      dispatch(fetchActualDates())
    }
  }, [])

  useEffect(() => {
    const starCountRef = ref(db, `notification/${localStorage.getItem('qr_code_token')}`);
    onValue(starCountRef, (snapshot) => {
      setCount(snapshot.size)
    });

    onChildAdded(starCountRef, (data) => {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        return new Notification(data.val().title);
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            return new Notification(data.val().title);
          }
        });
      }
    })
  }, [])

  useEffect(() => {
    dispatch(fetchPercentForService())
  }, [])

  return (
    <Root>
      {routesWithHeader.includes(pathname) && <Header/>}
      <ScrollToTop/>
      <Container>
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/notifications" element={<PrivateRoute Component={Notifications}/>}/>
          <Route path="/notification/:id" element={<PrivateRoute Component={NotificationDetail}/>}/>
          <Route path="/chat" element={<PrivateRoute Component={Chat}/>}/>
          <Route path="/management" element={<PrivateRoute Component={Management}/>}/>
          <Route path="/restaurant" element={<PrivateRoute Component={Restaurant}/>}/>
          <Route path="/venue/:id" element={<Venue/>}/>
          <Route path="/receipt/:id" element={<PrivateRoute Component={Receipt}/>}/>
          <Route path="/cart" element={<PrivateRoute Component={Cart}/>}/>
          <Route path="/sauna" element={<PrivateRoute Component={Sauna}/>}/>
          <Route path="/massage" element={<PrivateRoute Component={Massage}/>}/>
          <Route path="/cleaning" element={<PrivateRoute Component={Cleaning}/>}/>
          <Route path="/drycleaning" element={<PrivateRoute Component={DryCleaning}/>}/>
          <Route path="/phonebook" element={<PhoneBook/>}/>
          <Route path="/qrScan" element={<QrScan/>}/>
          <Route path="/file" element={<FileView/>}/>
          {!isAuth && (
            <Route path="/auth" element={<Auth/>}/>
          )}
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
        <Toaster/>
      </Container>
      {!hideNavbar && <Navbar count={count}/>}
    </Root>
  );
};

export default App;

const Root = styled.div`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`
