import Icons from './sprite.svg'
import styled from "styled-components";


const IconSvg = styled.svg`
  height: ${({height}) => height || '20px'};
  width: ${({width}) => width || '20px'};
  fill: ${({fill}) => fill || ''}
`
const Icon = (props) => {
    return (
        <IconSvg {...props}>
            <use href={Icons + '#' + props.id}/>
        </IconSvg>
    );
};

export default Icon;