import Notification from "../components/Notification";
import {Flex} from "../UI/globalStyles";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {set, ref, onChildAdded} from "firebase/database";
import {db} from "../firebase";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchNotifications} from "../store/main/mainAction";

const Notifications = () => {
    const notifications = useSelector(state => state.mainSlice.notifications)
    const dispatch = useDispatch()
    const starCountRef = ref(db, `notification/${localStorage.getItem('qr_code_token')}`);

    useEffect(() => {
        set(starCountRef, []);
        dispatch(fetchNotifications())
    },[])

    useEffect(() => {
        onChildAdded(starCountRef, (data) => {
            dispatch(fetchNotifications())
        })
    }, [])

    return (
        <div>
            <Flex direction='column' gap='12px'>
                {
                    notifications.map(item => (
                      <Notification key={item.id} {...item}/>
                    ))
                }
            </Flex>
        </div>
    );
};

export default Notifications;

const LinkA = styled(Link)`
  text-decoration: none;
  color: var(--text-color)
`