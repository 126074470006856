import {createSlice} from '@reduxjs/toolkit';
import {fetchCategories, fetchFoods, orderFoods} from "./kitchenAction";
import {getDate} from "../../utils";

const kitchenSlice = createSlice({
    name: 'kitchenSlice',
    initialState: {
        storageFoods: JSON.parse(localStorage.getItem('foods')) || [],
        foods: null,
        categories: null,
        order: {
            success: false,
            date: null,
        }
    },
    reducers: {
        addToCart(state, {payload}) {
            const foodId = payload.id;
            const existingFoodIndex = state.storageFoods.findIndex(item => item.id === foodId);

            if (existingFoodIndex !== -1) {
                state.storageFoods[existingFoodIndex].count += payload.count;
                state.storageFoods[existingFoodIndex].totalPrice += payload.totalPrice;
            } else {
                state.storageFoods.push(payload);
            }
        },
        deleteFromCard(state, {payload}) {
            state.storageFoods = state.storageFoods.filter(item => item.id !== payload)
        },
        incFoodInCart(state, {payload}) {
            const foodIndex = state.storageFoods.findIndex(item => item.id === payload);
            if (foodIndex !== -1) {
                state.storageFoods[foodIndex].count += 1;
                state.storageFoods[foodIndex].totalPrice += state.storageFoods[foodIndex].price;
            }
        },
        decFoodInCart(state, {payload}) {
            const foodIndex = state.storageFoods.findIndex(item => item.id === payload);
            if (foodIndex !== -1) {
                state.storageFoods[foodIndex].count -= 1;
                state.storageFoods[foodIndex].totalPrice -= state.storageFoods[foodIndex].price;
            }
        },
        resetCart: (state) => {
            state.storageFoods = []
            state.order = {
                success: false,
                date: null,
            }
        },
    },
    extraReducers: {
        [fetchFoods.fulfilled]:(state, {payload}) => {
            state.foods = payload
        },
        [fetchCategories.fulfilled]: (state, {payload}) => {
            state.categories = payload
        },
        [orderFoods.fulfilled]:(state) => {
            state.order = {
                success: true,
                date: getDate(),
            }
        }
    }
});

export const {
    addToCart,
    deleteFromCard,
    incFoodInCart,
    decFoodInCart,
  resetCart,
} = kitchenSlice.actions

export default kitchenSlice.reducer;