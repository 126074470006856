import React, {useEffect, useRef, useState} from 'react';
import {Flex, Title} from "../UI/globalStyles";
import {DatePicker, Loader} from "../components";
import styled from "styled-components";
import Icon from "../assets/Icons/Icon";
import {useTranslation} from "react-i18next";
import DynamicHeader from "../components/DynamicHeader";
import UiButton from "../UI/UiButton";
import {useOutsideClick} from "../hooks";
import {useDispatch, useSelector} from "react-redux";
import {orderSpa} from "../store/service/serviceAction";
import {useNavigate} from "react-router-dom";
import {toMain} from "../store/service/serviceSlice";
import {getDate} from "../utils";
import api from "../request";

const Sauna = () => {
   const success = useSelector(state => state.serviceSlice.success)
   const loading = useSelector(state => state.serviceSlice.loading)
   const {t} = useTranslation();
   const [duration, setDuration] = useState([])
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const [showDuration, setShowDuration] = useState(false)
   const [selectedDuration, setSelectedDuration] = useState('')
   const [selectedDate, setSelectedDate] = useState({date: '', time: '', isoDate: ''})
   const [errors, setErrors] = useState({date: '', duration: ''})
   const durationIndex = duration.find(({id}) => id === selectedDuration.id)
   const ref = useRef()
   useOutsideClick(ref, () => setShowDuration(false))
   const handleSelect = (id, title) => {
      setSelectedDuration({id, title})
      setShowDuration(false)
   }

   const handleOrder = () => {
      if(!selectedDate.date && !selectedDuration) {
         setErrors({duration: t('chooseDuration'), date: t('chooseDate')})
         return
      }
      if(!selectedDate.date) {
         return setErrors({...errors, date: t('chooseDate')})
      }
      if (!selectedDuration) {
         return setErrors({...errors, duration: t('chooseDuration')})
      }
      const date_time = new Date(selectedDate.rawDate).toISOString()
      const data = {
         date_time,
         duration: durationIndex.id
      }
      dispatch(orderSpa(data))
   }

   const navigateToMain = () => {
      dispatch(toMain())
      navigate('/')
   }

   useEffect(() => {
      api('/spa/category/').then(({data}) => setDuration(data))
      return () => {
         dispatch(toMain())
      }
   }, [])

   useEffect(() => {
      if(selectedDate.date || duration) {
         setErrors({duration: '', date: ''})
      }
   }, [selectedDate, selectedDuration])

   if (loading) {
      return <Loader/>
   }
   return (
      <Content>
         <DynamicHeader>{t('sauna')}</DynamicHeader>
         <Flex direction='column' fg='1'>
            {
              success ? (
                  <>
                     <>
                        <Receipt>
                           <Title ta='center'>{t('successOrderDescription')}</Title>
                           <DividerLine/>
                           <Flex direction='column' gap='12px'>
                              <GrayText>{getDate()}</GrayText>
                              <Flex jc='space-between'>
                                 <Title fs='14px'>{t('dateAndTime')}</Title>
                                 <GoldenText $fs='14px'>{`${selectedDate.date}, ${selectedDate.time}`}</GoldenText>
                              </Flex>
                              <Flex jc='space-between'>
                                 <Title fs='14px'>{t('procedure')}</Title>
                                 <GoldenText $fs='14px'>{t('sauna')}</GoldenText>
                              </Flex>
                              <Flex jc='space-between'>
                                 <Title fs='14px'>{t('duration')}</Title>
                                 <GoldenText $fs='14px'>{selectedDuration.title}</GoldenText>
                              </Flex>
                              <DividerLine/>
                              <Flex jc='space-between'>
                                 <Total>{t('total')}: </Total>
                                 <GoldenText fs='16px'>{t('free')}</GoldenText>
                              </Flex>
                           </Flex>
                        </Receipt>
                        <div style={{margin: 'auto 0 20px'}}>
                           <UiButton handleClick={navigateToMain}>
                              <Title ta='center' fs='16px'>{t('toMain')}</Title>
                           </UiButton>
                        </div>
                     </>
                  </>
               ) : (
                  <>
                     <Title mt='20px' fs='14px' mb='5px'>{t('dateAndTime')}</Title>
                     <RelativeContainer>
                        <DatePicker addedTime={40} selectedDate={selectedDate} setSelectedDate={setSelectedDate} error={errors.date}/>
                        <Label>{errors.date}</Label>
                     </RelativeContainer>
                     <Title fs='14px' mb='5px'>{t('duration')}</Title>
                     <InputContainer ref={ref}>
                        <RelativeContainer>
                           <SelectInput onClick={() => setShowDuration(!showDuration)} $error={errors.duration}>
                              <GrayText
                                 $selected={selectedDuration}>{selectedDuration ? selectedDuration.title : t('selectDuration')}</GrayText>
                              <ArrowBtn $open={showDuration} id='arrow' width='16px' height='16px'/>
                           </SelectInput>
                           <Label>{errors.duration}</Label>
                        </RelativeContainer>
                        {
                           showDuration && (
                              <DurationContainer>
                                 {
                                    duration.map(({id, title}, index) => (
                                       <div key={index} onClick={() => handleSelect(id, title)} style={{cursor: 'pointer'}}>
                                          {index !== 0 && <DividerLine/>}
                                          <Title fs='14px'>{title}</Title>
                                       </div>
                                    ))
                                 }
                              </DurationContainer>
                           )
                        }
                     </InputContainer>
                     <Flex direction='column' gap='20px' mt='auto' mb='20px'>
                        <PriceBlock>
                           <GrayTitle>{t('cost')}</GrayTitle>
                           <GoldenText>{t('free')}</GoldenText>
                        </PriceBlock>
                        <UiButton handleClick={handleOrder}>
                           <Flex gap='10px' jc='center'>
                              <Title fs='16px'>{t('placeAnOrder')}</Title>
                              <Title fw='700'>{t('free')}</Title>
                           </Flex>
                        </UiButton>
                     </Flex>
                  </>
               )
            }
         </Flex>
      </Content>
   );
};

export default Sauna;

const InputContainer = styled.div`
  position: relative;
`

const SelectInput = styled.div`
  border-radius: 10px;
  background: #F4F5F7;
  height: 44px;
  padding: 0 15px;
  cursor: pointer;
  color: #C0C0CF;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 14px;
  user-select: none;
  border: ${({$error}) => $error ? '1px solid red' : 'none'};
`

const ArrowBtn = styled(Icon)`
  margin-left: auto;
  transform: ${({$open}) => $open ? 'rotate(180deg)' : 'rotate(0deg)'};
`

const GrayText = styled.span`
  font-size: ${({$fs}) => $fs || '14px'};
  color: ${({$selected}) => $selected ? '#000' : '#C0C0CF'}
`

const DurationContainer = styled.div`
  position: absolute;
  width: 100%;
  margin: -10px 0 20px;
  border-radius: 12px;
  border: 1px solid #E7E6E6;
  background: #fff;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  user-select: none;
`

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: #F4F5F7;
  margin-bottom: 12px;
`

const PriceBlock = styled.div`
  border-radius: 16px;
  background: var(--background-color);
  padding: 0 20px;
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const GoldenText = styled.p`
  font-size: ${({$fs}) => $fs || '16px'};
  font-weight: 700;
  color: #D07B4B;
`

const GrayTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: var(--text-light);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
`

const Receipt = styled.div`
  border-radius: 16px;
  border: 1px solid var(--grey, #F4F5F7);
  background: #fff;
  box-shadow: 0 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  margin-top: auto;
`

const Total = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`

const RelativeContainer = styled.div`
  position: relative;
  padding-bottom: 15px;
`

const Label = styled.span`
  font-size: 13px;
  color: #fa0808;
  position: absolute;
  bottom: -2px;
  left: 5px;
`